import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
    Flex, Select, Text, Icon, Modal,
    ModalHeader, ModalBody, FormControl, FormLabel, Button, ModalOverlay, ModalContent,
    useDisclosure, NumberInputField, NumberInput, useToast
} from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import { Input } from '../../components/Form/Input';
import { FiPlus } from 'react-icons/fi';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FiAlertCircle } from 'react-icons/fi';
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'

const inviteFormSchema = yup.object().shape({
    name: yup.string().required("Benefit name is required!"),
});

const AdminOverview = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { register, handleSubmit, formState, reset } = useForm({
        resolver: yupResolver(inviteFormSchema),
    });
    const privateAxios = useAxiosPrivate();
    const [benefitTypes, setBenefitTypes] = useState([])
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState({})
    const { errors } = formState;
    const toast = useToast()

    const fetchData = async () => {
        try {
            const { data } = await privateAxios.get('/api/benefittypes/')
            setBenefitTypes(data)
            
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (values) => {
        if (modalType === "create")
            await createBenefitType(values)
        else changeBenefitType(modalData)
        await fetchData()
    }


    const showCreateModal = () => {
        setModalType('create')
        onOpen()
    }

    const showEditModalModal = (data) => {
        if (data.name === "Other expenses")
            setModalType('other')
        else setModalType('edit')
        setModalData(data)
        onOpen()
    }

    const showDeleteConfirmModal = (e, id) => {
        e.stopPropagation();
        setModalType('delete')
        setModalData({ id })
        onOpen()
    }

    const deleteBenefitType = async () => {
        const { id } = modalData
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.delete(`/api/admin/benefittype/${id}`)
            msg = message
        } catch (err) {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const createBenefitType = async (moData) => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.post(`/api/admin/benefittype/`, moData)
            msg = message
        } catch (err) {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const changeBenefitType = async (moData) => {
        const { id } = modalData
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.put(`/api/admin/benefittype/${id}`, moData)
            msg = message
        } catch (err) {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const BenefitTypeItem = ({ id, name, amount, unit }) => {
        return (
            <Flex onClick={() => showEditModalModal({ id, name, amount, unit })} border="1px #ECEDEF solid" alignItems="center" justifyContent="space-between" boxShadow="0px 10px 20px 0px #0000001A" borderRadius="8px" padding="4" gap="2" className="group hover:cursor-pointer">
                <Flex gap="2" alignItems="center" justifyContent="center" minWidth="100px">
                    <Flex flexDirection="column">
                        <Text className="group-hover:text-red-500 transition-all ease-in" fontWeight="bold">{name}</Text>
                        {name !== "Other expenses" ? <Text fontSize="md" color="#52595F">${amount} per {unit}</Text> : null}
                    </Flex>
                </Flex>
                <Flex gap="4">
                    <EditIcon className="transform transition-all ease-in" cursor="pointer" onClick={() => showEditModalModal({ id, name, amount, unit })} />
                    <TrashIcon className="transform transition-all ease-in" cursor="pointer" onClick={(e) => showDeleteConfirmModal(e, id)} />
                </Flex>
            </Flex>
        )
    }

    return (
        <>
            <Flex justifyContent="flex-end" m="4">
                <Button colorScheme="messenger" mt="8" onClick={showCreateModal}><Icon as={FiPlus} mr="2" />Create Benefit</Button>
            </Flex>
            <Flex flexDirection="column" gap="8" mt="8">
                {benefitTypes.map(({ id, name, amount, unit }, index) =>
                    <BenefitTypeItem key={index} id={id} name={name} amount={amount} unit={unit} />
                )}
            </Flex>
            <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                <ModalOverlay />
                <ModalContent bg="white">
                    <ModalHeader>
                        {modalType === 'edit' ? 'Edit' : null}
                        {modalType === 'delete' ? <Text><Icon fontSize="2xl" color="red" as={FiAlertCircle} mr="2" />Are you sure to delete this Benefit?</Text> : null}
                    </ModalHeader>
                    <ModalBody>
                        <Flex
                            as="form"
                            flexDir="column"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            {modalType === "edit" ? <>
                                <FormControl mb="4">
                                    <FormLabel >Type</FormLabel>
                                    <Input
                                        type="string"
                                        name="name"
                                        error={errors.name}
                                        {...register("name")}
                                        value={modalData.name}
                                        onChange={(e) => setModalData({ ...modalData, name: e.target.value })}
                                    />
                                </FormControl>
                                <FormControl mb="4">
                                    <FormLabel>Amount</FormLabel>
                                    <NumberInput
                                        min={0}
                                        value={modalData.amount}
                                        onChange={(value) => {
                                            setModalData({ ...modalData, amount: value })
                                        }}
                                        error={errors.amount}
                                    >
                                        <NumberInputField />
                                    </NumberInput>
                                </FormControl>
                                <FormControl mb="4">
                                    <FormLabel >Period</FormLabel>
                                    <Select placeholder='Select Type' value={modalData.unit} name="unit" {...register("unit")} onChange={(e) => setModalData({ ...modalData, unit: e.target.value })}>
                                        <option value={"2 year"}>2 years</option>
                                        <option value={"year"}>year</option>
                                        <option value={"month"}>month</option>
                                        <option value={"one time"}>one time</option>
                                    </Select>
                                </FormControl>
                                <Button className="transform hover:shadow-md transition-all ease-in" type='submit' colorScheme='green' my="4" isLoading={formState.isSubmitting}>Edit</Button>
                            </> : modalType === "create" ? <>
                                <FormControl mb="4">
                                    <FormLabel >Type</FormLabel>
                                    <Input
                                        type="string"
                                        name="name"
                                        error={errors.name}
                                        {...register("name")}
                                    />
                                </FormControl>
                                <FormControl mb="4">
                                    <FormLabel>Amount</FormLabel>
                                    <Input
                                        type="number"
                                        name="amount"
                                        min={0}
                                        error={errors.amount}
                                        {...register("amount")}
                                    />
                                </FormControl>
                                <FormControl mb="4">
                                    <FormLabel >Period</FormLabel>
                                    <Select placeholder='Select Type' name="unit" {...register("unit")}>
                                        {/* {benefits.map(({ type, id }, index) => <option key={index} value={type}>{type}</option>)} */}
                                        <option value={"2 year"}>2 years</option>
                                        <option value={"year"}>year</option>
                                        <option value={"month"}>month</option>
                                        <option value={"one time"}>one time</option>
                                    </Select>
                                </FormControl>
                                <Button className="transform hover:shadow-md transition-all ease-in" type='submit' colorScheme='green' my="4" isLoading={formState.isSubmitting}>Create</Button>
                            </> : modalType === "other" ? <>
                                <FormControl mb="4">
                                    <FormLabel >Type</FormLabel>
                                    <Input
                                        type="string"
                                        name="name"
                                        error={errors.name}
                                        {...register("name")}
                                        value={modalData.name}
                                        onChange={(e) => setModalData({ ...modalData, name: e.target.value })}
                                    />
                                </FormControl>
                                <Button className="transform hover:shadow-md transition-all ease-in" type='submit' colorScheme='green' my="4" isLoading={formState.isSubmitting}>Edit</Button>
                            </> : null}
                            {modalType === "delete" ?
                                <Flex justifyContent="end" gap="2" mt="2">
                                    <Button className="transform hover:shadow-md transition-all ease-in" _focus={{ border: 'none' }} colorscheme="messenger" onClick={() => deleteBenefitType()}>Yes</Button>
                                    <Button className="transform hover:shadow-md transition-all ease-in" _focus={{ border: 'none' }} colorScheme="red" onClick={() => onClose()}>No</Button>
                                </Flex> : null}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default AdminOverview;
