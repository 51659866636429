import React, { useEffect, useState } from 'react';
import { Button, Flex, Stack, Image, Text, Divider } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { authService } from '../services/authService';
import { useAuth } from '../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { Input } from './Form/Input';
import { FiInfo } from 'react-icons/fi';

const signInFormSchema = yup.object().shape({
    email: yup
        .string()
        .required("Email is required!")
        .email("Type a valid email!"),
    password: yup.string().required("Password is required!"),
});

const AdminLogin = () => {
    const navigate = useNavigate();
    const { auth, user, login } = useAuth();
    const [showWarning, setShowWarning] = useState(false)

    useEffect(() => {
        if (!auth || !user) return

        navigate(user.role === 'admin' ? '/overview' : '/home', {
            replace: true
        });
    }, [auth, user]) // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(signInFormSchema),
    });
    const { errors } = formState;

    const onSubmit = async (values) => {
        setShowWarning(false)
        try {
            const { status, data } = await authService.superLogin(values);
            if (status === 200) {
                login(data);
            }
            else
                console.warn(status, data);
        } catch (error) {
            setShowWarning(true)
            console.error(error);
        }
    };

    return (
        <Flex align="center" justify="center">
            <Flex
                as="form"
                w="100%"
                maxW={360}
                bg="white"
                py="8"
                borderRadius={8}
                flexDir="column"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Image px="8" src="Castr_main_logo_light-1.svg" className="mb-4" />
                <Text color="black" className="mb-4" px="8" textAlign="center">
                    Login with Super Password
                </Text>
                <Divider />
                <Stack spacing="4" px="8">
                    <Input
                        name="email"
                        type="email"
                        label="Email"
                        error={errors.email}
                        {...register("email")}
                    />
                    <Input
                        name="password"
                        type="password"
                        label="Password"
                        error={errors.password}
                        {...register("password")}
                    />
                </Stack>
                {showWarning ? <Flex gap="2" mt="6" justifyContent="center" bgColor="gray.600" borderRadius="5" py="2" color="orange.400" px="8">
                    <FiInfo className="mt-1" />
                    <Flex flexDir="column">
                        <Text>Wrong login credentials provided.</Text>
                        <Text>Pleage try again.</Text>
                    </Flex>
                </Flex> : null}
                <Button
                    type="submit"
                    mt="6"
                    mx="8"
                    colorScheme="messenger"
                    isLoading={formState.isSubmitting}
                >
                    Login
                </Button>
            </Flex>
        </Flex>
    );
};

export default AdminLogin;
