import React, { useEffect, useMemo, useRef, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
    Text, Box, Grid, Select, Textarea, Flex, Input, useToast, Tabs, TabList, Tab, TabPanels, TabPanel,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, FormControl, FormLabel, Button, Icon
} from '@chakra-ui/react';
import ReactDatepicker from '../components/Datepicker/ReactDatepicker'
import { Calendar } from '../components/Calendar'
import '../styles/react-yearly-calendar.css'
import { FaPaperclip } from 'react-icons/fa';
import { format } from 'date-fns';
import { IoMdClose } from 'react-icons/io';
import { timeOptions } from '../utils/constant';
import { usePublicData } from '../contexts/publicDataContext';

const LeaveRequest = () => {
    const privateAxios = useAxiosPrivate();
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [startingDate, setStartingDate] = useState(format(new Date(), 'yyyy.MM.dd'))
    const [startingTime, setStartingTime] = useState(timeOptions[0].value)
    const [endingDate, setEndingDate] = useState(format(new Date(), 'yyyy.MM.dd'))
    const [endingTime, setEndingTime] = useState('')
    const [attachment, setAttachment] = useState({ raw: null, name: null })
    const [reason, setReason] = useState('')
    const [type, setType] = useState('')
    const [typeErrMsg, setTypeErrMsg] = useState('')
    const [reasonErrMsg, setReasonErrMsg] = useState('')
    const fileIputRef = useRef()
    const [loading, setLoading] = useState(false)
    const [userInfo, setUserInfo] = useState(null)
    const { publicData } = usePublicData()
    const leaveTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.leaveTypes ? publicData.leaveTypes : []
    }, [publicData])
    const [disabledRanges, setDisabledRanges] = useState([])
    const [nextDisabledRanges, setNextDisabledRanges] = useState([])

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/users/me')
        setUserInfo(data)

        const { data: fullLeaves } = await privateAxios.get('/api/leaves/me')
        setDisabledRanges(fullLeaves.leaves.map(({ from, to }) => ([from, to])))
        setNextDisabledRanges(fullLeaves.nextLeaves.map(({ from, to }) => ([from, to])))
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (type) setTypeErrMsg('')
        else setTypeErrMsg('This field is required.')

        if (reason || attachment) setReasonErrMsg('')
        else setReasonErrMsg('This field is required')
    }, [type, reason, attachment, isOpen])

    const validate = () => {
        let ret = true

        if (!type) {
            setTypeErrMsg('This field is required.')
            ret = false
        } else setTypeErrMsg('')

        if (!reason && !attachment) {
            setReasonErrMsg('This field is required')
            ret = false
        } else setReasonErrMsg('')

        if (ret) {
            setTypeErrMsg('')
            setReasonErrMsg('')
        }

        return ret
    }

    const clearData = () => {
        if (!isOpen) {
            setStartingDate(format(new Date(), 'yyyy-MM-dd'))
            setEndingDate(format(new Date(), 'yyyy-MM-dd'))
        }
        setStartingTime(timeOptions[0].value)
        setEndingTime(filteredEndingTimeOptions[0].value)
        setAttachment(null)
        setReason('')
        setType('')
        setTypeErrMsg('')
        setReasonErrMsg('')
    }

    useEffect(() => {
        if (!isOpen) clearData()
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    const onDatePicked = (date, classes, isFirstHalf) => {
        setStartingDate(date.format('yyyy-MM-DD'))
        setEndingDate(date.format('yyyy-MM-DD'))
        setStartingTime(isFirstHalf ? "M" : "A")
        onOpen()
    }

    const filteredEndingTimeOptions = useMemo(() => {
        let ret = []
        if (startingDate === endingDate && startingTime === 'A') {
            ret = timeOptions.slice(2, 3)
            setEndingTime(ret[0].value)
        }
        if (startingTime === 'M') {
            ret = timeOptions.slice(1, 3)
            setEndingTime(ret[1].value)
        }

        return ret
    }, [startingDate, startingTime, endingDate])

    const handleFileSelect = (e) => {
        if (e.target.files.length) {
            setAttachment({
                name: e.target.files[0].name,
                raw: e.target.files[0]
            });
        }
    }

    const handleSendRequest = async () => {
        let msg = ''
        let alertType = 'success'

        if (!validate()) return

        try {
            let attachmentUrl = ''
            setLoading(true)
            if (attachment?.raw) {
                const formdata = new FormData()
                formdata.append("upload", attachment.raw)

                const { data } = await privateAxios.post('/api/file/upload', formdata)
                attachmentUrl = data.file
            }

            const { data } = await privateAxios.post('/api/leaves', {
                type,
                from: `${startingDate.replaceAll('-', '.')} ${startingTime}`,
                to: `${endingDate.replaceAll('-', '.')} ${endingTime}`,
                reason,
                attachment: attachmentUrl
            })

            msg = data.message
            fetchData()
        } catch (error) {
            alertType = 'error'
            if (error?.response?.data?.message) msg = error?.response?.data?.message
            else msg = "Error occurred"
        }

        setLoading(false)
        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        onClose()
    }

    const handleFileRemove = () => {
        fileIputRef.current.value = null
        setAttachment(null)
    }

    const currentContractRange = useMemo(() => {
        if (!userInfo?.contractualInfo) return []
        return [userInfo.contractualInfo.from, userInfo.contractualInfo.to]
    }, [userInfo])

    const nextContractRange = useMemo(() => {
        if (!userInfo?.nextContractualInfo) return []
        return [userInfo.nextContractualInfo.from, userInfo.nextContractualInfo.to]
    }, [userInfo])

    return (
        <>
            <Box overflowX="auto" display="block" mt="4">
                {currentContractRange.length
                    ? (<Tabs>
                        <TabList>
                            <Tab _focus={{ boxShadow: 'none' }}>Current Year</Tab>
                            <Tab _focus={{ boxShadow: 'none' }}>Next Year</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <Calendar key={disabledRanges.length} onPickDate={onDatePicked} range={currentContractRange} disabledRanges={disabledRanges} />
                            </TabPanel>
                            <TabPanel>
                                {nextContractRange.length ? <Calendar key={nextDisabledRanges.length + 1000} onPickDate={onDatePicked} range={nextContractRange} disabledRanges={nextDisabledRanges} /> : 'The next contract year is not set.'}
                            </TabPanel>
                        </TabPanels>
                    </Tabs>)
                    : <Text fontSize="2xl" fontWeight="bold">Your contract year is not set</Text>
                }
            </Box>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg="white" >
                    <ModalHeader>
                        <Text>Book time off</Text>
                    </ModalHeader>
                    <ModalBody py="4">
                        <FormControl>
                            <FormLabel >Type</FormLabel>
                            <Select placeholder="Select type" value={type} onChange={(e) => setType(parseInt(e.target.value))} >
                                {leaveTypes.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
                            </Select>
                        </FormControl>
                        {typeErrMsg ? <Text color="red.400" mt="4">{typeErrMsg}</Text> : null}
                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} mt="6">
                            <FormControl>
                                <FormLabel >Starting</FormLabel>
                                <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={!startingTime ? new Date() : new Date(`${startingDate}T00:00`)} onChange={(date) => setStartingDate(format(date, 'yyyy-MM-dd'))} weekendSelectable={false} />
                            </FormControl>
                            <FormControl>
                                <FormLabel >Time</FormLabel>
                                <Select value={startingTime} onChange={(e) => setStartingTime(e.target.value)}>
                                    {timeOptions.slice(0, 2).map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} mt="6">
                            <FormControl>
                                <FormLabel >Ending</FormLabel>
                                <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={!endingDate ? new Date() : new Date(`${endingDate}T00:00`)} onChange={(date) => setEndingDate(format(date, 'yyyy-MM-dd'))} weekendSelectable={false} />
                            </FormControl>
                            <FormControl>
                                <FormLabel >Time</FormLabel>
                                <Select value={endingTime} onChange={(e) => setEndingTime(e.target.value)}>
                                    {filteredEndingTimeOptions.map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <FormControl mt="6">
                            <Flex justifyContent="space-between" alignItems="center">
                                <FormLabel >Reason</FormLabel>
                                <Flex>
                                    {attachment?.name
                                        ? <Flex bgColor="blue.600" justifyContent="center" alignItems="center" borderRadius="5px" px="2" mr="2" mb="2" py="0.5">
                                            <Text mr="2" fontSize="sm">{attachment.name}</Text>
                                            <Text cursor="pointer" _hover={{ backgroundColor: "blue.400" }} onClick={handleFileRemove}><IoMdClose /></Text>
                                        </Flex>
                                        : null}
                                    <label htmlFor="upload-button" className='flex justify-center items-end'>
                                        <Icon fontSize="xl" children={<FaPaperclip />} mr="2" cursor="pointer" mb="1.5" />
                                        <Input ref={fileIputRef} id="upload-button" type="file" display="none" accept="image/*, .pdf" onChange={handleFileSelect} />
                                    </label>
                                </Flex>
                            </Flex>
                            <Textarea placeholder='Reason for time off...' value={reason} onChange={(e) => setReason(e.target.value)} />
                        </FormControl>
                        {reasonErrMsg ? <Text color="red.400" mt="4">{reasonErrMsg}</Text> : null}
                        <Flex justifyContent="end" mt="4"><Button colorscheme="messenger" isLoading={loading} onClick={handleSendRequest}>Send request</Button></Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default LeaveRequest;
