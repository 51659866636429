import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { momentObj } from 'react-moment-proptypes';

const propTypes = {
  classes: PropTypes.string,
  dayClicked: PropTypes.func.isRequired,
  dayHovered: PropTypes.func.isRequired,
  day: momentObj,
  title: PropTypes.string
};

const defaultProps = {
  classes: '',
  day: null,
  title: undefined
};

class Day extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onHover = this.onHover.bind(this);
  }

  onClick(isFirstHalf) {
    const { dayClicked, day } = this.props;
    dayClicked(day, isFirstHalf);
  }

  onHover() {
    const { dayHovered, day } = this.props;
    dayHovered(day);
  }

  onHalfHover(isFirstHalf) {
    const { dayClicked, day } = this.props;
    dayClicked(day, isFirstHalf);
  }

  render() {
    const { classes, day, title, disabledParts } = this.props;
    const isWeekend = classes && day && classes.includes('bolder')

    let filteredClasses = classes
    if (classes.includes('prev-month') || classes.includes('next-month')) filteredClasses = classes.replace('weekend', '')
    const notShowedDay = classes.includes('prev-month') || classes.includes('next-month') || classes.includes('selected')

    return (
      <td onMouseEnter={this.onHover} className={filteredClasses} title={title}>
        {isWeekend
          ?
          <span className="day-number">{day === null ? '' : day.date()}</span>
          :
          disabledParts === 'both'
            ?
            <span className="d-block cursor-not-allowed w-full d-flex justify-center items-center" style={{ height: '35px', backgroundColor: '#FFB1AC' }}>{day === null ? '' : day.date()}</span>
            :
            <div className='d-flex'>
              {disabledParts === 'first'
                ?
                <div className={day && !notShowedDay ? 'disabled-part' : ''}>
                  <div className='d-flex absolute justify-center items-center z-10' style={{ left: 'calc(50% - 5px)', top: 'calc(50% - 10px)' }}>
                    <span className="day-number">{day === null ? '' : day.date()}</span>
                  </div>
                </div>
                :
                <div className="half-select" onClick={() => this.onHalfHover(true)}>
                  <div className='d-flex absolute justify-center items-center z-10' style={{ left: 'calc(50% - 5px)', top: 'calc(50% - 10px)' }}>
                    <span className="day-number">{day === null ? '' : day.date()}</span>
                  </div>
                </div>
              }
              {disabledParts === 'last' ? <div className={day && !notShowedDay ? 'disabled-part' : ''}></div> : <div className="half-select" onClick={() => this.onHalfHover(false)}></div>}
            </div>
        }
      </td>
    );
  }
}

Day.propTypes = propTypes;
Day.defaultProps = defaultProps;

export default Day;
