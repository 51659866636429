import React, { useState, useMemo, useEffect } from 'react';
import { Grid, GridItem, Flex, Button, Input, Text, Select, useToast } from '@chakra-ui/react';
import { usePublicData } from '../contexts/publicDataContext';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate, useParams } from 'react-router-dom';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const { REACT_APP_BACKEND_URL: BACKEND_URL } = process.env

const NotesEdit = () => {
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [category, setCategory] = useState('')
    const [attatchments, setAttatchments] = useState([])
    const [creating, setCreating] = useState(false)
    const toast = useToast()
    const navigate = useNavigate()
    const params = useParams()
    const { publicData } = usePublicData()
    const privateAxios = useAxiosPrivate();
    const categories = useMemo(() => {
        if (!publicData) return []
        return publicData.categories ? publicData.categories : []
    }, [publicData])

    const fetchData = async () => {
        const { id } = params

        const { data } = await privateAxios.get(`/api/notes/${id}`)
        const { title: _title, content: _content, category: _category, attatchments: _attatchments } = data

        setTitle(_title)
        setContent(_content)
        setCategory(_category)
        setAttatchments(_attatchments)
    }

    useEffect(() => {
        fetchData()
    }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

    let pond = null; // eslint-disable-line no-unused-vars

    const handleEdit = async () => {
        let msg = ''
        let alertType = 'success'

        setCreating(true)
        try {
            const { id } = params

            const { data } = await privateAxios.put(`/api/admin/notes/${id}`, { title, content, category, files: attatchments })
            msg = data.message
        } catch (err) {
            alertType = 'error'
            msg = err.response?.data?.message
        }

        setCreating(false)
        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
        navigate(-1)
    }

    return <div className="mb-4">
        <Grid
            templateColumns='repeat(5, 1fr)'
            gap={4}
            mt={4}
        >
            <GridItem colSpan={3}>
                <Flex flexDirection="column" className='prose-h2:text-xl prose-h3:text-lg prose-h4:text-md prose-ol: prose-ul: '>
                    <Text fontWeight="bold">Title</Text>
                    <Input mb="6" value={title} onChange={(e) => setTitle(e.target.value)} />
                    <Text fontWeight="bold">Content</Text>
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        config={{
                            ckfinder: {
                                uploadUrl: `${BACKEND_URL}/api/file/upload`
                            },
                            removePlugins: ['List', 'Indent', 'Table', 'BlockQuote', 'MediaEmbed'],
                            link: {
                                decorators: {
                                    openInNewTab: {
                                        mode: 'manual',
                                        label: 'Open in a new tab',
                                        defaultValue: true,         // This option will be selected by default.
                                        attributes: {
                                            target: '_blank',
                                            rel: 'noopener noreferrer'
                                        }
                                    }
                                }
                            }
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();

                            const urls = data.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig); // eslint-disable-line no-useless-escape
                            const uploadedUrls = urls?.length ? urls.filter(url => url.includes(BACKEND_URL)): []

                            setAttatchments(uploadedUrls)
                            setContent(data)
                        }}
                    />
                </Flex>
            </GridItem>
            <GridItem colSpan={2}>
                <Flex justifyContent="space-between" gap="4" mt="6" alignItems="flex-end">
                    <Flex gap="10px" alignItems="center">
                        <Text fontWeight="bold">Category</Text>
                        <Select laceholder='Selecte category' value={category} onChange={(e) => setCategory(parseInt(e.target.value))}>
                            {categories.map(({ id, title }) => <option key={id} value={id}>{title}</option>)}
                        </Select>
                    </Flex>
                    <Button isLoading={creating} colorScheme="messenger" onClick={handleEdit}>Update</Button>
                </Flex>
            </GridItem>
        </Grid>
    </div>
};

export default NotesEdit;
