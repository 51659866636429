import React, { useEffect, useState, useRef, useMemo } from 'react';
import * as yup from "yup";
import { format } from 'date-fns';
import { yupResolver } from "@hookform/resolvers/yup";
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
    Flex, Select, Text, Grid, GridItem, Box, Icon, Divider, Modal, ModalHeader, ModalBody,
    FormControl, FormLabel, Button, ModalOverlay, ModalContent, useDisclosure,
    Textarea, useToast
} from '@chakra-ui/react';
import { useForm } from "react-hook-form";
import { IoMdClose } from 'react-icons/io';
import { FaPaperclip } from 'react-icons/fa';
import { FiFolderPlus } from 'react-icons/fi';
import { Input } from '../../components/Form/Input';
import { useAuth } from '../../contexts/authContext';
import { usePublicData } from '../../contexts/publicDataContext';

const inviteFormSchema = yup.object().shape({
    type: yup.number().required("Benefit is required!"),
    amount: yup.number().required("Amount is required!"),
    note: yup.string().required("Note is required!"),
});

const Overview = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(inviteFormSchema),
    });
    const privateAxios = useAxiosPrivate();
    const [benefits, setBenefits] = useState([])
    const [modalData, setModalData] = useState([]);
    const [attachments, setAttachments] = useState([])
    const fileIputRef = useRef()
    const { user } = useAuth();
    const { errors } = formState;
    const toast = useToast()
    const { publicData } = usePublicData()
    const benefitTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.benefitTypes ? publicData.benefitTypes : []
    }, [publicData])


    const fetchData = async () => {
        try {
            const { data } = await privateAxios.get('/api/benefits/me/overview')
            setBenefits(data);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (values) => {
        await handleSendRequest(values);
        await fetchData()
    }

    const handleSendRequest = async (reqData) => {
        let msg = ''
        let alertType = 'success'
        let userID = user.id;
        let currentDate = format(new Date(), 'yyyy-MM-dd');

        try {
            let attachmentUrls = []
            attachmentUrls = await Promise.all(attachments.map( async (attachment) => {
                const formdata = new FormData()
                formdata.append("upload", attachment)
                const { data } = await privateAxios.post('/api/file/upload', formdata)
                return data.file
            }))

            const { data } = await privateAxios.post('/api/benefits', {
                user: userID,
                type: reqData.type,
                amount: reqData.amount,
                note: reqData.note,
                date: currentDate,
                attachment: attachmentUrls.length ? JSON.stringify(attachmentUrls) : ''
            })

            msg = data.message
            fetchData()
        } catch (error) {
            alertType = 'error'
            if (error?.response?.data?.message) msg = error?.response?.data?.message
            else msg = "Error occurred"
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        onClose()
    }

    const handleFileSelect = (e) => {
        if (e.target.files.length) {
            setAttachments([...attachments, e.target.files[0]])
        }
    }

    const handleFileRemove = (att) => {
        fileIputRef.current.value = null
        setAttachments(attachments.filter(attachment => attachment.name !== att.name))
    }

    const showCreateModal = () => {
        onOpen()
    }

    const BenefitItem = ({ id, name, unit, usedAmount = 0, totalAmount }) => {
        return (
            <Box border="1px #ECEDEF solid" boxShadow="0px 10px 20px 0px #0000001A" borderRadius="4px" py="2" px="4" height="100%" padding="30px" cursor={'pointer'} className="group">
                <Flex justifyContent="center" flexDirection="column" alignItems="center" mb="8">
                    <Text fontSize="2xl" fontWeight="bold" mb="4" align="center" className="group-hover:text-red-500 transition-all ease-in">{name}</Text>
                    {name !== "Other expenses" ? <Text fontSize="xl" fontWeight="600">${totalAmount} per {unit}</Text> : null}
                </Flex>
                <Flex alignItems="center" my="2" justifyContent="space-around">
                    <Text fontSize="lg">Used - ${Number(usedAmount)}</Text>
                    {
                        name !== "Other expenses" ?
                            <>
                                <Divider orientation='vertical' height={'20px'} opacity={1} />
                                <Text fontSize="lg">Available - ${parseFloat((totalAmount - usedAmount).toFixed(2))}</Text>
                            </> : null}
                </Flex>
            </Box>
        )
    }

    return (
        <>
            <Flex justifyContent="flex-end" m="4">
                <Button className="transform hover:shadow-md transition-all ease-in" colorScheme="messenger" onClick={showCreateModal}><Icon as={FiFolderPlus} mr="2" fontSize="2xl" />Claim Benefits</Button>
            </Flex>
            <Box>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6} mt="5" mr={2}>
                    {benefits.map(({ id, name, unit, usedAmount, amount }, index) =>
                        <GridItem w='100%' key={index}>
                            <BenefitItem id={id} name={name} unit={unit} usedAmount={usedAmount} totalAmount={amount} />
                        </GridItem>
                    )}
                </Grid>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
                <ModalOverlay />
                <ModalContent bg="white">
                    <ModalHeader>
                        <Text>Claim Benefits</Text>
                    </ModalHeader>
                    <ModalBody>
                        <Flex
                            as="form"
                            flexDir="column"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <FormControl mb="4">
                                <FormLabel >Benefit</FormLabel>

                                <Select placeholder='Select Type' name="type" {...register("type")} onChange={(e) => setModalData({ ...modalData, type: e.target.value })}>
                                    {benefitTypes.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                                </Select>
                            </FormControl>
                            <FormControl mb="4">
                                <FormLabel>Amount</FormLabel>
                                <Input
                                    type="number"
                                    name="amount"
                                    min={0}
                                    error={errors.amount}
                                    {...register("amount")}
                                />
                            </FormControl>
                            <FormControl mb="4">
                                <Flex justifyContent="space-between" alignItems="center">
                                    <FormLabel >Reason</FormLabel>
                                    <Flex>
                                        {attachments.map((attachment, index) =>
                                            <Flex bgColor="blue.600" key={index} justifyContent="center" alignItems="center" borderRadius="5px" px="2" mr="2" mb="2" py="0.5">
                                                <Text mr="2" fontSize="sm">{attachment.name}</Text>
                                                <Text cursor="pointer" _hover={{ backgroundColor: "blue.400" }} onClick={() => handleFileRemove(attachment)}><IoMdClose /></Text>
                                            </Flex>
                                        )}
                                        <label htmlFor="upload-button" className='flex justify-center items-end'>
                                            <Icon fontSize="xl" children={<FaPaperclip />} mr="2" cursor="pointer" mb="1.5" />
                                            <Input ref={fileIputRef} id="upload-button" type="file" display="none" accept="image/*, .pdf" onChange={handleFileSelect} />
                                        </label>
                                    </Flex>
                                </Flex>
                                <Textarea name="note" {...register("note")} />
                            </FormControl>
                            <Button className="transform hover:shadow-md transition-all ease-in" type='submit' colorScheme='green' my="4" isLoading={formState.isSubmitting}>Claim</Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default Overview;
