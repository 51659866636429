import React, { useEffect, useState, useMemo } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
    Table, Thead, Tr, Th, Tbody, Td, Box, Badge, Flex, FormLabel, Select
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { usePublicData } from '../../contexts/publicDataContext';

const Requests = () => {
    const privateAxios = useAxiosPrivate();
    const [benefits, setBenefits] = useState([])
    const [searchKey, setSearchKey] = useState(null)
    const { publicData } = usePublicData()

    const benefitTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.benefitTypes ? publicData.benefitTypes : []
    }, [publicData])

    const filteredBenefits = useMemo(() => {
        const type = searchKey
        let ret = [...benefits]

        if (type) ret = ret.filter((benefit) => benefit.type === type)

        return ret
    }, [benefits,searchKey])

    const fetchData = async () => {
        try {
            const { data } = await privateAxios.get('/api/benefits/me')
            setBenefits(data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <>
            <Flex alignItems="center" flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                <FormLabel my="0">Type: </FormLabel>
                <Select placeholder='All' onChange={(e) => setSearchKey(e.target.value)} maxW="350px">
                    {benefitTypes.map(({ name, id }, index) => <option key={index} value={name}>{name}</option>)}
                </Select>
            </Flex>
            <Box overflowX="auto" display="block">
                <Table colorScheme="whiteAlpha" minWidth="860px">
                    <Thead>
                        <Tr borderBottom="2px #E2E2E2 solid">
                            <Th px="0.5">Type</Th>
                            <Th px="0.5">amount</Th>
                            <Th px="0.5">date</Th>
                            <Th px="0.5">reason</Th>
                            <Th px="0.5">status</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredBenefits.map((benefit, index) => (
                            <Tr key={index} borderBottom="2px solid #DBDFE2" cursor="pointer" className='transform hover:bg-gray-100 transition-all ease-in' >
                                <Td px="0.5">{benefit.type}</Td>
                                <Td px="0.5">{benefit.amount}</Td>
                                <Td px="0.5">{format(new Date(`${benefit.date}T00:00`), 'dd-MMMM-yyyy')}</Td>
                                <Td px="0.5">{benefit.note}</Td>
                                <Td px="0px">
                                    {benefit.approvedStatus === 1 ?
                                        <Badge className="!px-2" colorScheme='green'>Approved</Badge>
                                        : benefit.approvedStatus === 2 ?
                                            <Badge className="!px-2">Pending</Badge>
                                            : <Badge className="!px-2" colorScheme='red'>Rejected</Badge>
                                    }
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </>
    )
};

export default Requests;
