import React, { useEffect, useMemo, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Grid, GridItem } from '@chakra-ui/react';

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import multiMonthPlugin from '@fullcalendar/multimonth'
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import listPlugin from '@fullcalendar/list'
import '../styles/full-calendar-custom.css'
import '../styles/bootstrap.darkly.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import { endOfDay, format } from 'date-fns';

const Yearly = () => {
    const privateAxios = useAxiosPrivate();
    const [leaves, setLeaves] = useState([])
    const [birthdays, setBirthdays] = useState([])

    useEffect(() => {
    }, [privateAxios]);

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/admin/leaves')
        setLeaves(data.map(({ from, to, type, user }) => {
            const start = new Date(`${from.split(' ')[0]}T00:00`.replace(/[.]/g, "-"))
            const end = endOfDay(new Date(`${to.split(' ')[0]}T00:00`.replace(/[.]/g, "-")))
            const backgroundColor = type === 1 ? '#e74c3c' : type === 2 ? '#00bc8c' : '#f39c12'
            const title = user.name
            const cacheStart = format(start, 'yyyy-MM-dd')
            const cacheEnd = format(end, 'yyyy-MM-dd')
            if (cacheStart === cacheEnd)
                return { date: cacheStart, backgroundColor, borderColor: 'transparent', title }
            else
                return { start, end, backgroundColor, borderColor: 'transparent', title }
        }))

        const { data: _data } = await privateAxios.get('/api/admin/holidays')
        setBirthdays(_data.birthdays.map(({ birthday, name }) => {
            const startTime = new Date(`${birthday}T12:00:00`);
            const start = format(startTime, 'yyyy-MM-dd');
            const backgroundColor = '#e74c3c'
            const title = `${name}'s birthday`
            return { date: start, backgroundColor, borderColor: 'transparent', title }
        }))
    }

    const events = useMemo(() => {
        return [...leaves, ...birthdays]
    }, [leaves, birthdays])

    useEffect(() => {
        fetchData()
    }, [privateAxios]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDateSelect = (selectInfo) => {

    }

    const handleEventClick = (clickInfo) => {
    }

    const handleEvents = (events) => {
        // setCurrentEvents(events)
    }

    const renderEventContent = (eventInfo) => {
        return (
            <>
                <i>{eventInfo.event.title}</i>
            </>
        )
    }

    return (
        <div id="custom-calendar">
            <Grid gap={6} width="100%" templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(5, 1fr)' }} justifyContent="center">
                <GridItem colSpan={{ base: 1, md: 1 }}></GridItem>
                <GridItem colSpan={{ base: 5, md: 3 }}>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin, listPlugin, bootstrap5Plugin]}
                        headerToolbar={{
                            left: 'prev,next today',
                            center: 'title',
                            right: 'dayGridYear,dayGridMonth,listYear',
                        }}
                        themeSystem="bootstrap5"
                        initialView='dayGridYear'
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        dayMaxEvents={true}
                        weekends={true}
                        events={events} // alternatively, use the `events` setting to fetch from a feed
                        select={handleDateSelect}
                        eventContent={renderEventContent} // custom render function
                        eventClick={handleEventClick}
                        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                    /* you can update a remote database when these fire:
                    eventAdd={function(){}}
                    eventChange={function(){}}
                    eventRemove={function(){}}
                    */
                    />
                </GridItem>
                <GridItem colSpan={{ base: 1, md: 1 }}></GridItem>
            </Grid>
        </div>
    )
};

export default Yearly;
