import React, { useEffect, useMemo, useRef, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
    Text, Grid, Select, Textarea, Flex, Input, FormControl, FormLabel, Button, Icon
} from '@chakra-ui/react';
import ReactDatepicker from '../components/Datepicker/ReactDatepicker'
import '../styles/react-yearly-calendar.css'
import { FaPaperclip } from 'react-icons/fa';
import { format } from 'date-fns';
import { IoMdClose } from 'react-icons/io';
import { timeOptions } from '../utils/constant';
import { usePublicData } from '../contexts/publicDataContext';

const LeaveRequest = ({ addLeave }) => {
    const privateAxios = useAxiosPrivate();
    const [startingDate, setStartingDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [startingTime, setStartingTime] = useState(timeOptions[0].value)
    const [endingDate, setEndingDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [endingTime, setEndingTime] = useState('')
    const [attachment, setAttachment] = useState(null)
    const [reason, setReason] = useState('')
    const [type, setType] = useState('')
    const [typeErrMsg, setTypeErrMsg] = useState('')
    const [reasonErrMsg, setReasonErrMsg] = useState('')
    const fileIputRef = useRef()
    const { publicData } = usePublicData()
    const leaveTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.leaveTypes ? publicData.leaveTypes : []
    }, [publicData])

    useEffect(() => {
        if (type) setTypeErrMsg('')
        else setTypeErrMsg('This field is required.')

        if (reason || attachment) setReasonErrMsg('')
        else setReasonErrMsg('This field is required')
    }, [type, reason, attachment])

    useEffect(() => {
        validate()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const validate = () => {
        let ret = true

        if (!type) {
            setTypeErrMsg('This field is required.')
            ret = false
        } else setTypeErrMsg('')

        if (!reason && !attachment) {
            setReasonErrMsg('This field is required')
            ret = false
        } else setReasonErrMsg('')

        if (ret) {
            setTypeErrMsg('')
            setReasonErrMsg('')
        }

        return ret
    }

    const filteredEndingTimeOptions = useMemo(() => {
        let ret = []
        if (startingDate === endingDate && startingTime === 'A') {
            ret = timeOptions.slice(2, 3)
            setEndingTime(ret[0].value)
        }
        if (startingTime === 'M') {
            ret = timeOptions.slice(1, 3)
            setEndingTime(ret[1].value)
        }
        return ret
    }, [startingDate, startingTime, endingDate])

    const handleFileSelect = (e) => {
        if (e.target.files.length) {
            setAttachment({
                name: e.target.files[0].name,
                raw: e.target.files[0]
            });
        }
    }

    const handleSendRequest = async () => {
        if (!validate()) return

        try {
            let attachmentUrl = ''
            if (attachment?.raw) {
                const formdata = new FormData()
                formdata.append("upload", attachment.raw)

                const { data } = await privateAxios.post('/api/file/upload', formdata)
                attachmentUrl = data.file
            }

            addLeave({
                type,
                from: `${startingDate.replaceAll('-', '.')} ${startingTime.replaceAll('-', '.')}`,
                to: `${endingDate.replaceAll('-', '.')} ${endingTime.replaceAll('-', '.')}`,
                reason,
                attachment: attachmentUrl
            })

        } catch (error) {
            console.log(error)
        }
    }

    const handleFileRemove = () => {
        fileIputRef.current.value = null
        setAttachment(null)
    }

    return (
        <>
            <FormControl>
                <FormLabel >Type</FormLabel>
                <Select placeholder="Select type" value={type} onChange={(e) => setType(parseInt(e.target.value))} >
                    {leaveTypes.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
                </Select>
            </FormControl>
            {typeErrMsg ? <Text color="red.400" mt="4">{typeErrMsg}</Text> : null}
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} mt="6">
                <FormControl>
                    <FormLabel >Starting</FormLabel>
                    <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={!startingTime ? new Date() : new Date(`${startingDate}T00:00`)} onChange={(date) => setStartingDate(format(date, 'yyyy-MM-dd'))} weekendSelectable={false} />
                </FormControl>
                <FormControl>
                    <FormLabel >Time</FormLabel>
                    <Select value={startingTime} onChange={(e) => setStartingTime(e.target.value)}>
                        {timeOptions.slice(0, 2).map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} mt="6">
                <FormControl>
                    <FormLabel >Ending</FormLabel>
                    <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={!endingDate ? new Date() : new Date(`${endingDate}T00:00`)} onChange={(date) => setEndingDate(format(date, 'yyyy-MM-dd'))} weekendSelectable={false} />
                </FormControl>
                <FormControl>
                    <FormLabel >Time</FormLabel>
                    <Select value={endingTime} onChange={(e) => setEndingTime(e.target.value)}>
                        {filteredEndingTimeOptions.map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)}
                    </Select>
                </FormControl>
            </Grid>
            <FormControl mt="6">
                <Flex justifyContent="space-between" alignItems="center">
                    <FormLabel>Reason</FormLabel>
                    <Flex>
                        {attachment?.name
                            ? <Flex bgColor="blue.600" justifyContent="center" alignItems="center" borderRadius="5px" px="2" mr="2" mb="2" py="0.5">
                                <Text mr="2" fontSize="sm">{attachment.name}</Text>
                                <Text cursor="pointer" _hover={{ backgroundColor: "blue.400" }} onClick={handleFileRemove}><IoMdClose /></Text>
                            </Flex>
                            : null}
                        <label htmlFor="upload-button" className='flex justify-center items-end'>
                            <Icon fontSize="xl" children={<FaPaperclip />} mr="2" cursor="pointer" mb="1.5" />
                            <Input ref={fileIputRef} id="upload-button" type="file" display="none" accept="image/*, .pdf" onChange={handleFileSelect} />
                        </label>
                    </Flex>
                </Flex>
                <Textarea rows="5" placeholder='Reason for time off...' value={reason} onChange={(e) => setReason(e.target.value)} />
            </FormControl>
            {reasonErrMsg ? <Text color="red.400" mt="4">{reasonErrMsg}</Text> : null}
            <Flex justifyContent="end" mt="4"><Button colorscheme="messenger" onClick={handleSendRequest}>Add leave</Button></Flex>
        </>
    )
};

export default LeaveRequest;
