import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input as ChakraInput,
  } from "@chakra-ui/react";
  import { forwardRef } from "react";
//   import { FieldError } from "react-hook-form";
  
//   interface InputProps extends ChakraInputProps {
//     name: string;
//     label?: string;
//     error?: FieldError;
//   }
  
  const InputBase = (
    { name, label, error, ...rest },
    ref
  ) => {
    console.log(error);
    return (
      <FormControl isInvalid={!!error}>
        {!!label && <FormLabel  htmlFor={name}>{label}</FormLabel>}
        <ChakraInput
          name={name}
          id={name}
          size="md"
          ref={ref}
          {...rest}
        />
  
        {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
      </FormControl>
    );
  };
  
  export const Input = forwardRef(InputBase);
  