import React, { useEffect, useState, useMemo } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Flex, FormLabel, Select, Table, Thead, Tr, Td, Th, Tbody } from '@chakra-ui/react';
import allCountries from '../utils/countries.json'
import ReactDatepicker from '../components/Datepicker/ReactDatepicker'
import { format } from 'date-fns';

const Profile = () => {
    const privateAxios = useAxiosPrivate();
    const [holidays, setHolidays] = useState([])
    const [countries, setCountries] = useState([])
    const [search, setSearch] = useState({ country: null, date: null })
    const [selectedDate, setSelecedtDate] = useState(null)

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/admin/holidays')
        setHolidays(data.holidays)

        const tCountries = []
        data.countries.forEach((code) => {
            const country = allCountries.find(({ value }) => value === code)
            tCountries.push(country)
        })

        setCountries(tCountries)
    }

    useEffect(() => {
        setSearch({ ...search, date: selectedDate ? format(new Date(selectedDate), 'yyyy-MM-dd') : null })
    }, [selectedDate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fetchData()
    }, [privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps

    const filterHoliday = useMemo(() => {
        const { country, date } = search
        if (!country && !date) return holidays
        else if (country)return holidays.filter(holiday => holiday.country === country)
        else if (date) return holidays.filter(holiday => holiday.date === date)
        else return holidays.filter(holiday => holiday.date === date && holiday.country === country)
    }, [holidays, search])

    return (
        <>
            <Flex gap={6}>
                <Flex my={{ base: 0, xl: 5 }} justifyContent="center" alignItems="center">
                    <FormLabel my="0">Country:</FormLabel>
                    <Select placeholder='Select country' onChange={(e) => setSearch({ ...search, country: e.target.value })}>
                        {countries.map(({ name, value }, index) => <option key={index} value={value} >{name}</option>)}
                    </Select>
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                    <FormLabel my="0">Date:</FormLabel>
                    <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={selectedDate ? new Date(`${selectedDate}T00:00`) : null} onChange={(day) => setSelecedtDate(day ? format(day, 'yyyy-MM-dd') : null)} />
                </Flex>
            </Flex>
            <Table >
                <Thead>
                    <Tr>
                        <Th>Name</Th>
                        <Th>Date</Th>
                        <Th>Counry</Th>
                        <Th>Description</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {filterHoliday.map(({ name, date, description, country }, index) => (
                        <Tr key={index}>
                            <Td py="2">{name}</Td>
                            <Td py="2"px="0" width="100px" className='whitespace-nowrap'>{format(new Date(date),'dd-MMMM-yyyy')}</Td>
                            <Td py="2">{countries.find(({ value}) => value === country)?.name}</Td>
                            <Td py="2">{description}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </>
    )
};

export default Profile;
