import React, { useEffect, useState, useMemo } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Text, Grid, GridItem, Input, Flex, Button, FormControl, FormLabel, InputGroup, InputLeftElement, Select, useToast, InputRightElement, Avatar } from '@chakra-ui/react';
import ReactDatepicker from '../components/Datepicker/ReactDatepicker'
import { FiClock, FiCompass, FiFlag, FiKey, FiMail, FiPhone, FiUser } from 'react-icons/fi';
import { FaCrown } from 'react-icons/fa';
import { format } from 'date-fns';
import { useForm } from "react-hook-form";

import countries from '../utils/countries.json'
import { useAuth } from '../contexts/authContext';
import { usePublicData } from '../contexts/publicDataContext';

const Profile = () => {
    const privateAxios = useAxiosPrivate();
    const [error, setError] = useState({
        passwordError: null
    })
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [birthday, setBirthday] = useState(null)
    const [currentPassword, setCurrentPassword] = useState('')
    const [image, setImage] = useState({ preview: "", raw: "" })
    const [userInfo, setUserInfo] = useState({})

    const toast = useToast()
    const { register, handleSubmit, formState, setValue } = useForm();
    const { user, login } = useAuth();
    const isAdmin = useMemo(() => user?.role === 'admin', [user])
    const { publicData } = usePublicData()
    const departments = useMemo(() => {
        if (!publicData) return []
        return publicData.departments ? publicData.departments : []
    }, [publicData])


    useEffect(() => {
        const { name, email, contractualInfo, phone, country, address, birthday: birth, department } = userInfo
        setValue('name', name)
        setValue('email', email)
        setValue('contractual_info', contractualInfo?.from ? `${format(new Date(`${contractualInfo.from}T00:00`), 'dd MMMM yyyy')} - ${format(new Date(`${contractualInfo.to}T00:00`), 'dd MMMM yyyy')}` : '')
        setValue('department', departments.find(({ id }) => id === department)?.name)
        setValue('phone', phone)
        setValue('country', country)
        setValue('address', address)
        const [bYear, bMonth, bDay] = birth ? birth.split('-') : format(new Date(), 'yyyy-MM-dd').split('-')
        setBirthday(birth ? format(new Date(bYear, bMonth - 1, bDay), 'yyyy-MM-dd') : null)
    }, [userInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    const fetchData = async () => {
        try {
            const { status, data } = await privateAxios.get('/api/users/me')
            if (status !== 200) return

            setUserInfo(data)
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (confirmPassword === password || !password.length || !confirmPassword.length) setError({ ...error, passwordError: null })
        else setError({ ...error, passwordError: 'Passwords must match.' })
    }, [password, confirmPassword]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (values) => {
        values.birthday = birthday
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { status, data } = await privateAxios.post('/api/users/me', values)
            if (status !== 200) return

            setUserInfo(data)
            login({ ...user, name: data.name })
            msg = "Updating profile was success"
        } catch (err) {
            alertType = "error"
            msg = "Updating profile was failed"
            console.error(err)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
    }

    const handleSetPassword = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        if (error.passwordError?.length || !password.length || !currentPassword.length) return

        try {
            const { data: { message } } = await privateAxios.post('/api/users/me/set-password', {
                currentPassword,
                newPassword: password
            })
            msg = message
        } catch (err) {
            alertType = 'error'
            if (err.response.status === 403) msg = err.response.data.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        setCurrentPassword('')
        setPassword('')
        setConfirmPassword('')
    }

    const handleChangeAvatar = async () => {
        try {
            const formdata = new FormData()
            formdata.append("upload", image.raw)

            const { data } = await privateAxios.post('/api/file/upload', formdata)
            const { data: _data } = await privateAxios.post('/api/users/me', { ...userInfo, avatar: data.file })
            setUserInfo(_data)
            login({ ...user, name: _data.name, avatar: _data.avatar })
        } catch (err) {
            console.log(err)
        }
    }

    const handleChange = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    }

    return (
        <>
            <Flex alignItems="end" justifyContent="space-between" flexDir={{ base: 'column', md: 'row' }} gap="4">
                <Flex alignItems="end" gap={6}>
                    <label htmlFor="upload-button">
                        <Avatar size='2xl' src={image.preview ? image.preview : userInfo.avatar} cursor="pointer" />
                        <Input id="upload-button" type="file" display="none" accept="image/*" onChange={handleChange} />
                    </label>
                    <Button colorScheme="green" onClick={handleChangeAvatar} px="2" disabled={!image.preview}>Change avatar</Button>
                </Flex>
            </Flex>
            <Grid gap={6} width="100%" templateColumns={{ base: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(3, 1fr)' }}>
                <GridItem colSpan={{ base: 1, md: 2 }}>
                    <Flex
                        onSubmit={handleSubmit(onSubmit)}
                        as="form"
                        flexDir="column"
                        mt="4"

                    >
                        <Flex gap={{ base: 0, md: 6 }} flexDir={{ base: 'column', md: 'row' }}>
                            <FormControl>
                                <FormLabel mb="2">Name</FormLabel>
                                <InputGroup>
                                    <InputLeftElement children={<FiUser />} />
                                    <Input
                                        label="Name"

                                        mb="4"
                                        {...register("name")}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl mb="4">
                                <FormLabel mb="2">Birthday</FormLabel>
                                <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={birthday ? new Date(`${birthday}T00:00`) : ''} onChange={(day) => setBirthday(format(day, 'yyyy-MM-dd'))} />
                            </FormControl>
                        </Flex>
                        <Flex gap={{ base: 0, md: 6 }} flexDir={{ base: 'column', md: 'row' }}>
                            <FormControl>
                                <FormLabel mb="2">Email</FormLabel>
                                <InputGroup>
                                    <InputLeftElement children={<FiMail />} />
                                    <Input
                                        disabled={!isAdmin}
                                        mb="4"
                                        {...register("email")}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel mb="2">Contractual Info</FormLabel>
                                <InputGroup>
                                    <InputLeftElement children={<FiClock />} />
                                    <Input
                                        disabled
                                        mb="4"
                                        {...register("contractual_info")}
                                    />
                                </InputGroup>
                            </FormControl>
                        </Flex>
                        <Flex gap={{ base: 0, md: 6 }} flexDir={{ base: 'column', md: 'row' }}>
                            <FormControl>
                                <FormLabel mb="2">Department</FormLabel>
                                <InputGroup>
                                    <InputLeftElement children={<FiFlag />} />
                                    <Input
                                        label="Department"
                                        disabled
                                        mb="4"
                                        {...register("department")}
                                    />
                                    {userInfo.role === 'departmentmanager' ? <InputRightElement children={<FaCrown color="green" />} /> : null}
                                </InputGroup>
                            </FormControl>
                            <FormControl>
                                <FormLabel mb="2">Phone Number</FormLabel>
                                <InputGroup>
                                    <InputLeftElement children={<FiPhone />} />
                                    <Input
                                        label="Phone Number"
                                        mb="4"
                                        {...register("phone")}
                                    />
                                </InputGroup>
                            </FormControl>
                        </Flex>
                        <Flex gap={{ base: 0, md: 6 }} flexDir={{ base: 'column', md: 'row' }}>
                            <FormControl>
                                <FormLabel mb="2">Address</FormLabel>
                                <InputGroup>
                                    <InputLeftElement children={<FiCompass />} />
                                    <Input
                                        label="Address"
                                        mb="4"
                                        {...register("address")}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl mb="4">
                                <FormLabel mb="2">Country</FormLabel>
                                <Select {...register("country")} >
                                    {countries.map(({ name, value }, index) => <option key={index} value={value}>{name}</option>)}
                                </Select>
                            </FormControl>
                        </Flex>
                        <Flex justifyContent="right"><Button type='submit' colorScheme="green" isLoading={formState.isSubmitting}>Save</Button></Flex>
                    </Flex>
                </GridItem>
                <GridItem colSpan={1}>
                    <Flex flexDir="column" mt="4" >
                        <FormControl>
                            <FormLabel>You joined on</FormLabel>
                            <Input disabled value={userInfo.joinedDate ? format(new Date(`${userInfo.joinedDate}T00:00`), 'dd-MMMM-yyyy') : ''} mb="4" />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Current password</FormLabel>
                            <InputGroup>
                                <InputLeftElement children={<FiKey />} />
                                <Input
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    type="password"
                                    mb="4"
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Password</FormLabel>
                            <InputGroup>
                                <InputLeftElement children={<FiKey />} />
                                <Input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    mb="4"
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Confirm Password</FormLabel>
                            <InputGroup>
                                <InputLeftElement children={<FiKey />} />
                                <Input
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    type="password"
                                    mb="4"
                                />
                            </InputGroup>
                        </FormControl>
                        {error.passwordError ? <Text color="red" fontSize="sm">{error.passwordError}</Text> : null}
                        <Flex justifyContent="right"><Button colorScheme="green" onClick={handleSetPassword}>Set Password</Button></Flex>
                    </Flex>
                </GridItem>
            </Grid>
        </>
    )
};

export default Profile;
