import React, { useMemo } from 'react';
import { Flex, Text, Image, Badge, Menu, MenuList, MenuItem, MenuButton, IconButton } from '@chakra-ui/react'
import { useAuth } from '../contexts/authContext';
import { usePublicData } from '../contexts/publicDataContext';
import { format } from 'date-fns';
import { FaEllipsisH, FaEdit, FaTrash, FaShare, FaBan, FaEye } from 'react-icons/fa';
import { noteStatus } from '../utils/constant'

const { NOTE_STATUS_DRAFT, NOTE_STATUS_PUBLISHED, NOTE_STATUS_DROPPED } = noteStatus

const NoteCard = ({ id, title, content, category, status, posterImg, publishedAt, updatedAt, createdAt, onPreview, onDrop, onPublish, onEdit, onDelete }) => {
    const { user } = useAuth();
    const { publicData } = usePublicData()
    const isAdmin = useMemo(() => user?.role === 'admin', [user])
    const categories = useMemo(() => {
        if (!publicData) return []
        return publicData.categories ? publicData.categories : []
    }, [publicData])
    const categoryTitle = useMemo(() => {
        const cat = categories.find((data) => data.id === category)

        if (!cat) return null
        else return cat.title
    }, [categories, category])

    const onCardClick = () => {
        if (!isAdmin) onPreview()
    }

    return <Flex minHeight="250px" flexDirection="column" padding="10px" border="1px solid #ECEDEF" pos="relative" borderRadius="5px" boxShadow="0px 5px 5px 0px #0000001A" cursor="pointer" onClick={() => onCardClick()}>
        {isAdmin ?
            status === NOTE_STATUS_PUBLISHED ? <Image src="rib_published.png" width={150} pos="absolute" top={0} left={0} /> :
                status === NOTE_STATUS_DROPPED ? <Image src="rib_dropped.png" width={150} pos="absolute" top={0} left={0} /> :
                    status === NOTE_STATUS_DRAFT ? <Image src="rib_draft.png" width={150} pos="absolute" top={0} left={0} /> : null
            : null}
        {isAdmin ? <Flex justifyContent="flex-end">
            <Menu>
                <MenuButton
                    as={IconButton}
                    icon={<FaEllipsisH />}
                    _focus={{ boxShadow: 'none' }}
                    size="sm"
                    variant="outline"
                />
                <MenuList width="100px">
                    <MenuItem icon={<FaEye />} onClick={() => onPreview()}>Preview</MenuItem>
                    {status === NOTE_STATUS_PUBLISHED ? <MenuItem icon={<FaBan />} onClick={() => onDrop()}>Drop</MenuItem> :
                        status === NOTE_STATUS_DROPPED ? <MenuItem icon={<FaShare />} onClick={() => onPublish()}>Republish</MenuItem> :
                            status === NOTE_STATUS_DRAFT ? <MenuItem icon={<FaShare />} onClick={() => onPublish()}>Publish</MenuItem> : null}
                    <MenuItem icon={<FaEdit />} onClick={() => onEdit()}>Edit</MenuItem>
                    <MenuItem color="red" icon={<FaTrash />} onClick={() => onDelete()}>Delete</MenuItem>
                </MenuList>
            </Menu>
        </Flex > : null}
        {!isAdmin ? <Text fontSize="xs" color="grey" align="right" mt={4}>{`${format(new Date(publishedAt), 'dd MMMM yyyy')}`}</Text> :
            status === NOTE_STATUS_PUBLISHED ? <Text fontSize="xs" color="grey" align="right" mt={4}>{`Published on ${format(new Date(publishedAt), 'dd MMMM yyyy')}`}</Text> :
                status === NOTE_STATUS_DROPPED ? <Text fontSize="xs" color="grey" align="right" mt={4}>{`Dropped on ${format(new Date(updatedAt), 'dd MMMM yyyy')}`}</Text> :
                    status === NOTE_STATUS_DRAFT ? <Text fontSize="xs" color="grey" align="right" mt={4}>{`Created on ${format(new Date(createdAt), 'dd MMMM yyyy')}`}</Text> : null}

        <Text fontSize="lg" fontWeight="bold" color="black" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">{title}</Text>
        {posterImg ? <Image src={posterImg} alt={title} mb="20px" mt="5px" maxHeight="200px" objectFit="cover" /> : <Text mb="20px" fontSize="sm">{content.slice(0, 150)} {content.length > 150 ? '...' : ''}</Text>}
        {categoryTitle ? <Badge fontSize="xs" pos="absolute" right="0" bottom='0' padding="1px 5px" borderRadius="3px" variant='subtle' colorScheme='blue'>{categoryTitle}</Badge> : null}
    </Flex >
}

export default NoteCard