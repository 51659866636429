import React, { useEffect, useState } from 'react';
import { Button, Flex, Stack, Image, Text, useToast, Divider } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { authService } from '../services/authService';
import { useAuth } from '../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import { Input } from './Form/Input';
import { FiChevronLeft, FiInfo } from 'react-icons/fi';

const signInFormSchema = yup.object().shape({
    email: yup
        .string()
        .required("Email is required!")
        .email("Type a valid email!"),
    password: yup.string().required("Password is required!"),
});

const Login = () => {
    const navigate = useNavigate();
    const { auth, user, login } = useAuth();
    const [forgetPaassword, setForgetPassword] = useState(false)
    const [showWarning, setShowWarning] = useState(false)
    const toast = useToast()

    useEffect(() => {
        if (!auth || !user) return

        navigate(user.role === 'admin' ? '/overview' : '/home', {
            replace: true
        });
    }, [auth, user]) // eslint-disable-line react-hooks/exhaustive-deps

    const { register, handleSubmit, formState, setValue } = useForm({
        resolver: yupResolver(signInFormSchema),
    });
    const { errors } = formState;

    useEffect(() => {
        if (forgetPaassword) setValue('password', ' ')
        else setValue('password', '')
    }, [forgetPaassword]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (values) => {
        setShowWarning(false)
        if (forgetPaassword) {
            try {
                const { email } = values
                const { data } = await authService.forgetPassword({ email: email });

                toast({
                    title: data.message,
                    status: 'success',
                    duration: 4000,
                    isClosable: true,
                })
            } catch (error) {
                console.error(error);
            }
        } else {
            try {
                const { status, data } = await authService.login(values);
                if (status === 200) {
                    login(data);
                }
                else
                    console.warn(status, data);
            } catch (error) {
                setShowWarning(true)
                console.error(error);
            }
        }
    };

    return (
        <Flex align="center" justify="center">
            <Flex
                as="form"
                w="100%"
                maxW={360}
                bg="white"
                py="8"
                borderRadius={8}
                flexDir="column"
                onSubmit={handleSubmit(onSubmit)}
            >
                <Image px="8" src="Castr_main_logo_light-1.svg" className="mb-4" />
                {!forgetPaassword ? <Text color="black" className="mb-4" px="8" textAlign="center">
                    Welcome to Castr Team 👋🏻
                </Text> : null}
                <Divider />
                {forgetPaassword ? <Flex justifyContent={forgetPaassword ? "start" : "end"} my="2" px="8">
                    <Text color="blue.600" _hover={{ textDecoration: 'underline' }} onClick={() => setForgetPassword(!forgetPaassword)} cursor="pointer"><Flex alignItems="center"><FiChevronLeft></FiChevronLeft>Back to login</Flex></Text>
                </Flex> : null}
                <Stack spacing="4" px="8">
                    <Input
                        name="email"
                        type="email"
                        label="Email"
                        error={errors.email}
                        {...register("email")}
                    />
                    {!forgetPaassword ? <Input
                        name="password"
                        type="password"
                        label="Password"
                        error={errors.password}
                        {...register("password")}
                    /> : null}
                </Stack>
                {!forgetPaassword ? <Flex justifyContent={forgetPaassword ? "start" : "end"} mb="2" px="8">
                    <Text color="blue.600" _hover={{ textDecoration: 'underline' }} onClick={() => setForgetPassword(!forgetPaassword)} cursor="pointer">Forgot password?</Text>
                </Flex> : null}
                {showWarning && !forgetPaassword ? <Flex gap="2" mt="6" justifyContent="center" bgColor="gray.600" borderRadius="5" py="2" color="orange.400" px="8">
                    <FiInfo className="mt-1" />
                    <Flex flexDir="column">
                        <Text>Wrong login credentials provided.</Text>
                        <Text>Pleage try again.</Text>
                    </Flex>
                </Flex> : null}
                {!forgetPaassword ? <Button
                    type="submit"
                    mt="6"
                    mx="8"
                    colorScheme="messenger"
                    isLoading={formState.isSubmitting}
                >
                    Login
                </Button> : <Button
                    type="submit"
                    mt="6"
                    mx="8"
                    colorscheme="messenger"
                    isLoading={formState.isSubmitting}
                >
                    Reset password
                </Button>}
            </Flex>
        </Flex>
    );
};

export default Login;
