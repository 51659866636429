import React, { useEffect, useState, /* useMemo */ } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { Text, Flex, Grid, GridItem, Icon, Avatar } from '@chakra-ui/react';
import { CircleFlag } from 'react-circle-flags'
import { format } from 'date-fns';
import { ReactComponent as AbsenteeIcon } from '../assets/icons/absentee.svg'
import { ReactComponent as BirthdayIcon } from '../assets/icons/cake-candle.svg'
import { ReactComponent as HolidayIcon } from '../assets/icons/holiday.svg'

const Overview = () => {
    const privateAxios = useAxiosPrivate();
    const [birthday, setBirthday] = useState([])
    const [absentees, setAbsentees] = useState([])
    const [holidays, setHolidays] = useState([])
    const [upcomingBirthday, setUpcomingBirthday] = useState([])

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/admin/reports/today')
        setBirthday(data.birthday)
        setAbsentees(data.absentees)
        setHolidays(data.holidays)
        setUpcomingBirthday(data.upcomingBirthday)
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps

    const AbsenteeItem = ({ leave }) => {
        return (
            <Flex border="1px #ECEDEF solid" boxShadow="0px 10px 20px 0px #0000001A" borderRadius="4px" padding="4" gap="2">
                <Flex gap="2" alignItems="center" justifyContent="center" minWidth="100px">
                    <Avatar name={leave.user.name} src={leave.user.avatar} size="md" />
                    <Flex flexDirection="column">
                        <Text fontWeight="bold">{leave.user.name}</Text>
                        <Text fontSize="xs" color="#52595F">
                            {format(new Date(`${leave.from.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd MMMM yyyy')} {leave.from.split(" ")[1]} -
                            {format(new Date(`${leave.to.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd MMMM yyyy')} {leave.to.split(" ")[1]}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    const BirthdayItem = ({ user }) => {
        return (
            <Flex border="1px #ECEDEF solid" boxShadow="0px 10px 20px 0px #0000001A" borderRadius="4px" padding="4" gap="2" className="congrats-bg">
                <Flex alignItems="center" justifyContent="center" minWidth="100px" gap="2">
                    <Avatar name={user.name} src={user.avatar} size="md" />
                    <Text fontWeight="bold">{user.name}</Text>
                </Flex>
            </Flex>
        )
    }

    return (
        <>
            <Text mt="4" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                <Icon as={AbsenteeIcon} mr="2" />
                Today's absentees
                <span className="nums-icon">{absentees.length}</span>
            </Text>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={6} mt="5" ml={2}>
                {absentees.map((leave, index) => {
                    return (
                        <GridItem w='100%' key={index}>
                            <AbsenteeItem leave={leave} />
                        </GridItem>
                    )
                })}
            </Grid>
            <Text mt="6" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                <Icon as={AbsenteeIcon} mr="2" />
                Today's birthday
                <span className="nums-icon">{birthday.length}</span>
            </Text>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={6} mt="5" ml={2}>
                {birthday.map((user, index) => {
                    return (
                        <GridItem w='100%' key={index}>
                            <BirthdayItem user={user} />
                        </GridItem>
                    )
                })}
            </Grid>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} mt="6">
                <GridItem className="d-flex" flexDirection="column" gap="4" p="4">
                    <Text fontSize="xl" fontWeight="bold"><Icon as={HolidayIcon} mr="2" />Holidays</Text>
                    {holidays.map(({ country, name, date }, index) => <Flex key={index} justifyContent="space-between" px="2">
                        <Flex gap="2" alignItems="center">
                            <CircleFlag countryCode={country.toLowerCase()} style={{ width: '32px' }} />
                            <Text fontWeight="bold" fontSize="md">{name}</Text>
                        </Flex>
                        <Text fontSize="sm" color="#6B7278">{format(new Date(`${date}T00:00:00`), 'dd MMMM')}</Text>
                    </Flex>)}
                </GridItem>
                <GridItem flexDirection="column" gap="4" p="4" className="d-flex congrats-bg">
                    <Text fontSize="xl" fontWeight="bold"><Icon as={BirthdayIcon} mr="2" />Upcoming birthdays</Text>
                    {upcomingBirthday.map(({ name, birthday }, index) => {
                        const parsedBirthday = birthday.replaceAll('.', '-')
                        return <Flex key={index} justifyContent="space-between" px="2">
                            <Flex gap="2" alignItems="center">
                                <Avatar src="" name="Lee ChangHe" size="sm" />
                                <Text fontWeight="bold" fontSize="md">{name}</Text>
                            </Flex>
                            <Text fontSize="sm" color="#6B7278">{format(new Date(`${parsedBirthday}T00:00:00`), 'dd MMMM')}</Text>
                        </Flex>
                    })}
                </GridItem>
            </Grid>
        </>
    )
};

export default Overview;
