import { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { useStorage } from './storageContext';
import { useAuth } from './authContext';
import useAxiosPrivate from '../hooks/useAxiosPrivate';

const PublicDataContext = createContext(null);

const gePublicDataState = (storage) => {
    const publicData = storage.publicData();
    return publicData;
};

export const PublicDataProvider = ({ children }) => {
    const storage = useStorage();
    const [publicData, setPublicData] = useState(() => gePublicDataState(storage));
    const { auth } = useAuth();
    const privateAxios = useAxiosPrivate()

    const refreshData = async () => {
        const { data } = await privateAxios.get('/api/data/public')
        setData(data)
    }

    useEffect(() => {
        if (!auth) return removeData()

        refreshData()
    }, [auth, privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps

    const setData = useCallback(
        (data) => {
            storage.setPublicData(data);
            setPublicData(gePublicDataState(storage));
        },
        [storage]
    );

    const removeData = useCallback(() => {
        storage.removePublicData();
        setPublicData(null);
    }, [storage]);

    return (
        <PublicDataContext.Provider
            value={{
                publicData,
                setData,
                removeData,
                refreshData,
            }}
        >
            {children}
        </PublicDataContext.Provider>
    );
};

export const usePublicData = () => {
    const publicData = useContext(PublicDataContext);
    if (!publicData) throw Error('usePublicData should use whitin a <PublicDataProvider />.');

    return publicData;
};
