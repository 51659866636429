import React, { forwardRef } from "react";
import ReactDatePicker from "react-datepicker";
import { InputGroup, Input, InputRightElement } from "@chakra-ui/react";
import { FiCalendar } from 'react-icons/fi';

import "react-datepicker/dist/react-datepicker.css";
import "./chakra-react-datepicker.css";
import { isSaturday, isSunday } from "date-fns";

const customDateInput = ({ value, onClick, onChange, onMouseDown, ...props }, ref) => (
    <Input
        autoComplete="off"
        background="transparent"
        value={value}
        ref={ref}
        onClick={onClick}
        onChange={onChange}
        onMouseDown={onMouseDown}
        minWidth="140px"
        {...props}
    />
);
customDateInput.displayName = "DateInput";

const CustomInput = forwardRef(customDateInput);

const icon = <FiCalendar fontSize="sm" />;

const disableWeekend = (date) => {
    if (isSaturday(date) || isSunday(date)) return false
    return true
}

const DatePicker = ({ selectedDate, onChange, onMouseDown, weekendSelectable = true, ...props }) => {
    return (
        <>
            <InputGroup className="light-theme">
                <ReactDatePicker
                    minWidth="200px"
                    dateFormat="dd-MMMM-yyyy"
                    selected={selectedDate}
                    onChange={onChange}
                    className="react-datapicker__input-text"
                    customInput={<CustomInput {...props} onMouseDown={onMouseDown} />}
                    {...props}
                    filterDate={weekendSelectable ? (date) => true : (date) => disableWeekend(date)}
                />
                <InputRightElement children={icon} zIndex="3" />
            </InputGroup>
        </>
    );
};

export default DatePicker;
