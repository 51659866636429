import React, { useMemo, useState, useEffect } from 'react';
import {
    IconButton, Text, Grid, Flex, Button, Icon, useToast, useDisclosure,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody
} from '@chakra-ui/react';
import NoteCard from '../components/NoteCard'
import { FiPlus } from 'react-icons/fi';
import { FaBan, FaShare, FaTrash, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { usePublicData } from '../contexts/publicDataContext';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { noteStatus } from '../utils/constant'
import HTMLRenderer from 'react-html-renderer'

const { NOTE_STATUS_DRAFT, NOTE_STATUS_PUBLISHED, NOTE_STATUS_DROPPED } = noteStatus

const Notes = () => {
    const [notes, setNotes] = useState([])
    const [filteredNotes, setFilteredNotes] = useState([])
    const [modalData, setModalData] = useState(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user } = useAuth();
    const privateAxios = useAxiosPrivate();
    const isAdmin = useMemo(() => user?.role === 'admin', [user])
    const { publicData } = usePublicData()
    const toast = useToast()
    const categories = useMemo(() => {
        if (!publicData) return []
        return publicData.categories ? publicData.categories : []
    }, [publicData])
    const [categoryFilter, setCategoryFilter] = useState(null)

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/notes')
        setNotes(data)
    }

    useEffect(() => {
        fetchData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!modalData) return

        const note = notes.find(({ id }) => id === modalData.id)
        setModalData(note)
    }, [notes, modalData])

    useEffect(() => {
        const cloned = [...notes]
        if (!categoryFilter) return setFilteredNotes(cloned)

        setFilteredNotes(cloned.filter(({ category }) => category === categoryFilter))
    }, [notes, categoryFilter])

    const navigate = useNavigate();

    const handleDrop = async (id) => {
        let msg = ''
        let alertType = 'success'

        try {
            const { data } = await privateAxios.put(`/api/admin/notes/${id}/drop`)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
    }

    const handlePublish = async (id) => {
        let msg = ''
        let alertType = 'success'

        try {
            const { data } = await privateAxios.put(`/api/admin/notes/${id}/publish`)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
    }

    const handleEdit = async (id) => {
        navigate(`/notes/${id}/edit`)
    }

    const handleDelete = async (id) => {
        let msg = ''
        let alertType = 'success'

        try {
            const { data } = await privateAxios.delete(`/api/admin/notes/${id}`)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
    }

    const handleCreate = () => {
        navigate('/notes/create')
    }

    const handlePreview = (noteId) => {
        const note = notes.find(({ id }) => id === noteId)
        setModalData(note)
        onOpen()
    }

    return <>
        <Flex justifyContent="space-between" mt="6">
            <Flex gap={2}>
                <Button _focus={{ boxShadow: 'none' }} variant="ghost" onClick={() => setCategoryFilter(null)} backgroundColor={categoryFilter === null ? 'gray.100' : 'transparent'}>All</Button>
                {categories.map(({ title, id }) => <Button key={id} _focus={{ boxShadow: 'none' }} backgroundColor={categoryFilter === id ? 'gray.100' : 'transparent'} variant="ghost" onClick={() => setCategoryFilter(id)}>{title}</Button>)}
            </Flex>
            {isAdmin ? <Button colorScheme="messenger" onClick={handleCreate}><Icon as={FiPlus} mr="2" />Create new note</Button> : null}
        </Flex>
        <Grid templateColumns={{ base: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))', lg: 'repeat(4, minmax(0, 1fr))', xl: 'repeat(5, minmax(0, 1fr))' }} gap={6} mt="6" ml={2}>
            {filteredNotes.map(
                ({ id, title, content, category, status, posterImg, publishedAt, createdAt, updatedAt }, index) =>
                    <NoteCard
                        key={index}
                        id={id}
                        title={title}
                        content={content ? content.replace(/<\/?[^>]+(>|$)/g, " ") : ''}
                        category={category}
                        status={status}
                        posterImg={posterImg}
                        publishedAt={publishedAt}
                        updatedAt={updatedAt}
                        createdAt={createdAt}
                        onPreview={() => handlePreview(id)}
                        onDrop={() => handleDrop(id)}
                        onPublish={() => handlePublish(id)}
                        onEdit={() => handleEdit(id)}
                        onDelete={() => handleDelete(id)}
                    />
            )}
        </Grid>

        <Modal isOpen={isOpen} onClose={onClose} size="6xl">
            <ModalOverlay />
            <ModalContent bg="white">
                <ModalHeader>
                    <Flex justifyContent="space-between">
                        <Text fontWeight="bold" fontSize="2xl">{modalData?.title}</Text>
                        <IconButton onClick={() => onClose()} borderRadius="50%" icon={<FaTimes />} variant="ghost" _focus={{ boxShadow: 'none' }} />
                    </Flex>
                </ModalHeader>
                <ModalBody className='prose-h2:text-xl prose-h3:text-lg prose-h4:text-md note-content' mb="4">
                    {modalData?.content ? <HTMLRenderer html={modalData?.content} /> : null}
                    {isAdmin ? <Flex gap="10px" justifyContent="flex-end" mt="4">
                        {modalData?.status === NOTE_STATUS_PUBLISHED ? <Button onClick={() => handleDrop(modalData.id)}><Icon as={FaBan} mr="2" />Drop</Button> :
                            modalData?.status === NOTE_STATUS_DROPPED ? <Button onClick={() => handlePublish(modalData.id)}><Icon as={FaShare} mr="2" />Republish</Button> :
                                modalData?.status === NOTE_STATUS_DRAFT ? <Button onClick={() => handlePublish(modalData.id)}><Icon as={FaShare} mr="2" />Publish</Button> : null}
                        <Button onClick={() => handleDelete(modalData.id)}><Icon as={FaTrash} mr="2" />Delete</Button>
                    </Flex> : null}
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
};

export default Notes;
