import axios from 'axios';

const { REACT_APP_BACKEND_URL: BACKEND_URL } = process.env

export const axiosPublic = axios.create({
    baseURL: BACKEND_URL,
});

export const axiosPrivate = axios.create({
    baseURL: BACKEND_URL,
});
