import { axiosPublic } from '../api/axios';

export const authService = {
    refreshToken: (token) => {
        return axiosPublic.post('/api/auth/refreshtoken', {
            refreshToken: token,
        });
    },

    register: (data) => {
        return axiosPublic.post(
            '/api/auth/signup',
            {
                ...data,
            }
        );
    },

    login: (data) => {
        return axiosPublic.post(
            '/api/auth/signin',
            {
                ...data,
            }
        );
    },

    superLogin: (data) => {
        return axiosPublic.post(
            '/api/auth/superSignin',
            {
                ...data,
            }
        );
    },

    setPassword: (data) => {
        return axiosPublic.post(
            '/api/users/verify-invitation',
            {
                ...data,
            }
        );
    },

    forgetPassword: (data) => {
        return axiosPublic.post(
            '/api/auth/reset-password',
            {
                ...data,
            }
        );
    },
};
