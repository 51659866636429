import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
    Flex, Select, Text, Grid, GridItem, Box, Icon, Divider, useDisclosure, Modal, Input as ChakraInput,
    ModalHeader, ModalBody, FormControl, FormLabel, Button, ModalOverlay, ModalContent, Avatar,
    useToast
} from '@chakra-ui/react';
import { FiPlus } from 'react-icons/fi';
import { ReactComponent as UserPlusIcon } from '../assets/icons/user-plus.svg'
import { Input } from '../components/Form/Input';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { usePublicData } from '../contexts/publicDataContext';
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg'
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg'

const createDepartmentSchema = yup.object().shape({
    name: yup.string().required("This field is required!"),
});

const Department = () => {
    const privateAxios = useAxiosPrivate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [departments, setDepartments] = useState([])
    const [users, setUsers] = useState([])
    const { refreshData } = usePublicData()
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState({})
    const toast = useToast()

    const { register, handleSubmit, formState, reset } = useForm({
        resolver: yupResolver(createDepartmentSchema),
    });
    const { errors } = formState;

    const fetchData = async () => {
        try {
            const { data } = await privateAxios.get('/api/departments')
            setDepartments(data)

            const { data: _data } = await privateAxios.get('/api/admin/users')
            setUsers(_data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]); // eslint-disable-line react-hooks/exhaustive-deps

    const showDeleteConfirmModal = (id) => {
        setModalType('delete')
        setModalData({ id })
        onOpen()
    }

    const showEditModalModal = (data) => {
        setModalType('edit')
        setModalData(data)
        onOpen()
    }

    const deleteDepartment = async () => {
        const { id } = modalData
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.delete(`/api/admin/departments/${id}`)
            msg = message
        } catch (err) {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const DeaprtmentItem = ({ id, name, manager, members }) => {
        return (
            <Box py="2" px="4" borderRadius={10} border="1px #ECEDEF solid" height="100%">
                <Flex justifyContent="space-between" alignItems="center" mb="4">
                    <Text fontSize="2xl" fontWeight="bold" cursor={'pointer'} className="hover:underline">{name}</Text>
                    <Flex gap="2">
                        <EditIcon cursor="pointer" onClick={() => showEditModalModal({ id, name, manager, members })} />
                        <TrashIcon cursor="pointer" onClick={() => showDeleteConfirmModal(id)} />
                    </Flex>
                </Flex>
                <Flex alignItems="center" my="2">
                    <Flex justifyContent="space-around" width="100%" minHeight="160px">
                        <Flex gap={2} ml="2" flexDir="column" justifyContent="center" alignItems="center">
                            {manager.name ? <Avatar name={manager.name} src={manager.avatar} size="xl" /> : <Icon fontSize="xl" m="4" as={UserPlusIcon} />}
                            <Text fontWeight="bold">{manager.name ? manager.name : 'No manager'}</Text>
                        </Flex>
                        <Divider orientation='vertical' height={'auto'} />
                        <Flex justifyContent="space-between" m={2}>
                            <Flex flexDir="column">
                                {members.slice(0, 3).map((member, index) =>
                                    <Flex mb="2" key={index} gap="2" alignItems="center">
                                        <Avatar name={member.name} src={member.avatar} size="sm" />
                                        <Text >{member.name}</Text>
                                    </Flex>
                                )}
                                {members.length > 3 ? <Text >{`${members.length - 3} people more`}</Text> : null}
                                {members.length === 0 ? <Text >No members</Text> : null}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Box>
        )
    }

    const handleCreateDepartment = async (values) => {
        await privateAxios.post('/api/admin/departments', values)

        await refreshData()
        await fetchData()
        onClose()
        reset()
    }

    const showCreateDepartmentModal = () => {
        setModalType('create')
        setModalData({})
        onOpen()
    }

    const changeDpartment = async () => {
        const { id } = modalData
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.put(`/api/admin/departments/${id}`, modalData)
            msg = message
        } catch (err) {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    return (
        <Box mt="4">
            <Button colorScheme="messenger" onClick={showCreateDepartmentModal}><Icon as={FiPlus} mr="2" />New Department</Button>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6} mt="5" mr={2}>
                {departments.map(({ name, manager, members, id }, index) =>
                    <GridItem w='100%' key={index}>
                        <DeaprtmentItem id={id} name={name} manager={manager} members={members} />
                    </GridItem>
                )}
            </Grid>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg="white" >
                    <ModalHeader>
                        {modalType === 'delete' ? 'Are you sure to delete this department?' : null}
                        {modalType === 'create' ? 'Create new department' : null}
                        {modalType === 'edit' ? 'Change department name' : null}
                    </ModalHeader>
                    <ModalBody>
                        {modalType === 'edit' ?
                            <Flex gap="2" my="2" flexDirection="column">
                                <ChakraInput value={modalData.name} onChange={(e) => setModalData({ ...modalData, name: e.target.value })} />
                                <Flex justifyContent="end">
                                    <Button _focus={{ border: 'none' }} colorscheme="messenger" onClick={() => changeDpartment()}>Change</Button>
                                </Flex>
                            </Flex>
                            : null
                        }
                        {modalType === 'delete' ?
                            <Flex justifyContent="end" gap="2" mt="2">
                                <Button _focus={{ border: 'none' }} colorscheme="messenger" onClick={() => deleteDepartment()}>Yes</Button>
                                <Button _focus={{ border: 'none' }} colorScheme="red" onClick={() => onClose()}>No</Button>
                            </Flex>
                            : null
                        }

                        {modalType === 'create' ?
                            <Flex
                                as="form"
                                flexDir="column"
                                onSubmit={handleSubmit(handleCreateDepartment)}
                                mb="4"
                            >
                                <FormControl mb="4">
                                    <Input
                                        label="Department name"
                                        name="name"
                                        error={errors.name}

                                        {...register("name")}
                                    />
                                </FormControl>
                                <FormControl mb="4">
                                    <FormLabel>Department manager</FormLabel>
                                    <Select placeholder='Select manager' name="manager" {...register("manager")}>
                                        {users.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
                                    </Select>
                                </FormControl>
                                <Flex width="100%" justifyContent="right">
                                    <Button colorScheme="green" type="submit" isLoading={formState.isSubmitting}>Create</Button>
                                </Flex>
                            </Flex>
                            : null
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
};

export default Department;
