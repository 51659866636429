import React, { useEffect } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useLocation, useNavigate } from 'react-router-dom'

const SlackWebHookRedirect = () => {
    const privateAxios = useAxiosPrivate();
    const location = useLocation();
    const navigate = useNavigate();

    const checkCode = async (code) => {
        try {
            await privateAxios.post('/api/admin/slack/oauth/exchange', { code: code })
            navigate('/setting')
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        if(!params.code) return navigate('/')

        checkCode(params.code)
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps


    return <></>
};

export default SlackWebHookRedirect;
