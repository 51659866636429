export const timeOptions = [
    { value: "M", label: "Morning" },
    { value: "A", label: "Afternoon" },
    { value: "E", label: "Evening" },
]

export const userStatus = {
    USER_STATUS_ACTIVE: 1,
    USER_STATUS_PENDING: 2,
    USER_STATUS_DEACTIVATED: 3
}

export const leaveStatus = {
    LEAVE_STATUS_APPROVED: 1,
    LEAVE_STATUS_PENDING: 2,
    LEAVE_STATUS_REJECTED: 3,
}

export const leaveType = {
    LEAVE_TYPE_SICK: 1,
    LEAVE_TYPE_PAID: 2,
    LEAVE_TYPE_UNPAID: 3,
    LEAVE_TYPE_PUBLIC_HOLIDAY: 4,
}

export const benefitStatus = {
    BENEFIT_STATUS_APPROVED: 1,
    BENEFIT_STATUS_PENDING: 2,
    BENEFIT_STATUS_REJECTED: 3,
}

export const benefitType = {
    BENEFIT_UNIT_YEAR: 1,
    BENEFIT_UNIT_MONTH: 2,
    BENEFIT_UNIT_ONE: 3,
}

export const noteStatus = {
    NOTE_STATUS_DRAFT: 1,
    NOTE_STATUS_PUBLISHED: 2,
    NOTE_STATUS_DROPPED: 3
}

export const timeSlots = [
    { value: '', label: '12:00 AM' }, { value: '', label: '12:30 AM' },
    { value: '', label: '01:00 AM' }, { value: '', label: '01:30 AM' },
    { value: '', label: '02:00 AM' }, { value: '', label: '02:30 AM' },
    { value: '', label: '03:00 AM' }, { value: '', label: '03:30 AM' },
    { value: '', label: '04:00 AM' }, { value: '', label: '04:30 AM' },
    { value: '', label: '05:00 AM' }, { value: '', label: '05:30 AM' },
    { value: '', label: '06:00 AM' }, { value: '', label: '06:30 AM' },
    { value: '', label: '07:00 AM' }, { value: '', label: '07:30 AM' },
    { value: '', label: '08:00 AM' }, { value: '', label: '08:30 AM' },
    { value: '', label: '09:00 AM' }, { value: '', label: '09:30 AM' },
    { value: '', label: '10:00 AM' }, { value: '', label: '10:30 AM' },
    { value: '', label: '11:00 AM' }, { value: '', label: '11:30 AM' },
    { value: '', label: '12:00 PM' }, { value: '', label: '12:30 PM' },
    { value: '', label: '01:00 PM' }, { value: '', label: '01:30 PM' },
    { value: '', label: '02:00 PM' }, { value: '', label: '02:30 PM' },
    { value: '', label: '03:00 PM' }, { value: '', label: '03:30 PM' },
    { value: '', label: '04:00 PM' }, { value: '', label: '04:30 PM' },
    { value: '', label: '05:00 PM' }, { value: '', label: '05:30 PM' },
    { value: '', label: '06:00 PM' }, { value: '', label: '06:30 PM' },
    { value: '', label: '07:00 PM' }, { value: '', label: '07:30 PM' },
    { value: '', label: '08:00 PM' }, { value: '', label: '08:30 PM' },
    { value: '', label: '09:00 PM' }, { value: '', label: '09:30 PM' },
    { value: '', label: '10:00 PM' }, { value: '', label: '10:30 PM' },
    { value: '', label: '11:00 PM' }, { value: '', label: '11:30 PM' },
]
