import React, { useMemo, useEffect, useState, useRef } from 'react';
import {
    Flex, Box, Text, Grid, Icon, Table, Thead, Th, Tbody, Tr, Td, Button, Badge, Select, Input, Textarea,
    Modal, ModalHeader, ModalBody, ModalContent, useDisclosure, ModalOverlay, useToast,
    FormControl, FormLabel,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { IoMdClose } from 'react-icons/io';
import { FaDownload, FaEdit, FaTrash, FaPaperclip } from 'react-icons/fa';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { timeOptions } from '../utils/constant';
import ReactDatepicker from '../components/Datepicker/ReactDatepicker'
import { usePublicData } from '../contexts/publicDataContext';

const LeaveStatus = () => {
    const privateAxios = useAxiosPrivate();
    const [leaves, setLeaves] = useState([])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState(null)
    const [startingDate, setStartingDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [startingTime, setStartingTime] = useState(timeOptions[0].value)
    const [endingDate, setEndingDate] = useState(format(new Date(), 'yyyy-MM-dd'))
    const [endingTime, setEndingTime] = useState('')
    const [attachment, setAttachment] = useState({ raw: null, name: null })
    const [reason, setReason] = useState('')
    const [type, setType] = useState('')
    const fileIputRef = useRef()
    const [loading, setLoading] = useState(false)
    const toast = useToast()
    const { publicData } = usePublicData()
    const leaveTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.leaveTypes ? publicData.leaveTypes : []
    }, [publicData])

    const filteredEndingTimeOptions = useMemo(() => {
        let ret = []
        if (startingDate === endingDate && startingTime === 'A') ret = timeOptions.slice(2, 3)
        else ret = timeOptions.slice(1, 3)

        setEndingTime(ret[0].value)
        return ret
    }, [startingDate, startingTime, endingDate])

    const handleFileRemove = () => {
        setModalData({ ...modalData, attachment: null })
        setAttachment(null)
    }

    const handleFileSelect = (e) => {
        if (e.target.files.length) {
            setAttachment({
                name: e.target.files[0].name,
                raw: e.target.files[0]
            });
        }
    }

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/report/me')

        setLeaves(data.leaves)
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleDownload = async (file) => {
        const regexAll = /^(.*[\\\/])?(.+)\.(.+)$/;  // eslint-disable-line no-useless-escape
        const ext = file.match(regexAll)[3]

        const outsideRes = await fetch(file);
        const blob = await outsideRes.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `report.${ext}`;
        link.click();
    }

    const handleUpdateLeave = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            let attachmentUrl = null
            setLoading(true)
            if (attachment?.raw) {
                const formdata = new FormData()
                formdata.append("upload", attachment.raw)

                const { data } = await privateAxios.post('/api/file/upload', formdata)
                attachmentUrl = data.file
            }
            const { data } = await privateAxios.put(`/api/leaves/${modalData.id}`, {
                type,
                from: `${startingDate.replace(/-/g, '.')} ${startingTime}`,
                to: `${endingDate.replace(/-/g, '.')} ${endingTime}`,
                reason,
                attachment: attachmentUrl ? attachmentUrl : modalData.attachment
            })
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        onClose()
        setLoading(false)
    }

    const openDeleteModal = (leave) => {
        setModalType('delete')
        setModalData(leave)
        onOpen()
    }

    const openEditModal = (leave) => {
        setModalType('edit')
        setModalData(leave)
        setType(leave.type)
        const [sDate, sTime] = leave.from.split(' ')
        setStartingDate(sDate.replaceAll('.', '-'))
        setStartingTime(sTime)
        const [eDate, eTime] = leave.to.split(' ')
        setEndingDate(eDate.replaceAll('.', '-'))
        setEndingTime(eTime)
        setReason(leave.reason)
        onOpen()
    }

    const handleDeleteLeave = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            setLoading(true)

            const { data } = await privateAxios.delete(`/api/leaves/${modalData.id}`)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        onClose()
        setLoading(false)
    }

    return (
        <>
            <Box overflowX="auto" display="block" mt="2">
                <Table colorScheme="whiteAlpha">
                    <Thead>
                        <Tr borderBottom="2px #E2E2E2 solid">
                            <Th>Requested Date</Th>
                            <Th>Type</Th>
                            <Th>Reason</Th>
                            <Th>Status</Th>
                            <Th>From - To</Th>
                            <Th>Days</Th>
                            <Th></Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {leaves.map((leave, index) => {
                            return (
                                <Tr key={index} borderBottom="2px #E2E2E2 solid">
                                    <Td><Text fontWeight="bold" >{format(new Date(leave.requestedDate), 'dd-MMMM-yyyy')}</Text></Td>
                                    <Td>{leaveTypes.find((type) => type.id === leave.type)?.name}</Td>
                                    <Td>{leave.reason}</Td>
                                    <Td>{leave.approvedStatus === 1 ? <Badge className="!px-2" colorScheme='green'>Approved</Badge> : leave.approvedStatus === 2 ? <Badge className="!px-2">Pending</Badge> : <Badge className="!px-2" colorScheme='red'>Rejected</Badge>}</Td>
                                    <Td>
                                        {format(new Date(`${leave.from.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd MMMM yyyy')} {leave.from.split(" ")[1]} -
                                        {format(new Date(`${leave.to.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd MMMM yyyy')} {leave.to.split(" ")[1]}
                                    </Td>
                                    <Td>{`${leave.duration}`}</Td>
                                    <Td>{leave.attachment ? <FaDownload onClick={() => handleDownload(leave.attachment)} cursor="pointer" /> : null}</Td>
                                    <Td>
                                        {leave.approvedStatus === 2 ?
                                            <Flex gap="2">
                                                <Button colorScheme="red" size="sm" onClick={() => openEditModal(leave)} p="0"><FaEdit /></Button>
                                                <Button colorScheme="purple" size="sm" onClick={() => openDeleteModal(leave)} p="0"><FaTrash /></Button>
                                            </Flex>
                                            : null}
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </Table>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} isCentered={modalType === 'delete'}>
                <ModalOverlay />
                <ModalContent bg="white" >
                    <ModalHeader>
                        {modalType === 'delete' ?
                            <Text fontSize="xl">Are you sure to delete it?</Text>
                            : null}
                    </ModalHeader>
                    <ModalBody mb="2">
                        {modalType === 'delete' ?
                            <Flex gap="2" justifyContent="end">
                                <Button colorScheme="purple" onClick={handleDeleteLeave} size="md">Yes</Button>
                                <Button colorScheme="pink" onClick={onClose}>No</Button>
                            </Flex>
                            : null}
                        {modalType === 'edit' ?
                            <>
                                <FormControl>
                                    <FormLabel >Type</FormLabel>
                                    <Select placeholder="Select type" value={type} onChange={(e) => setType(e.target.value)} >
                                        {leaveTypes.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
                                    </Select>
                                </FormControl>
                                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} mt="6">
                                    <FormControl>
                                        <FormLabel >Starting</FormLabel>
                                        <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={!startingTime ? new Date() : new Date(`${startingDate}T00:00`)} onChange={(date) => setStartingDate(format(date, 'yyyy-MM-dd'))} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel >Time</FormLabel>
                                        <Select value={startingTime} onChange={(e) => setStartingTime(e.target.value)}>
                                            {timeOptions.slice(0, 2).map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6} mt="6">
                                    <FormControl>
                                        <FormLabel >Ending</FormLabel>
                                        <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={!endingDate ? new Date() : new Date(`${endingDate}T00:00`)} onChange={(date) => setEndingDate(format(date, 'yyyy-MM-dd'))} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel >Time</FormLabel>
                                        <Select value={endingTime} onChange={(e) => setEndingTime(e.target.value)}>
                                            {filteredEndingTimeOptions.map(({ value, label }, index) => <option key={index} value={value}>{label}</option>)}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <FormControl mt="6">
                                    <Flex justifyContent="space-between" alignItems="center">
                                        <FormLabel >Reason</FormLabel>
                                        <Flex>
                                            {modalData?.attachment
                                                ?
                                                <Flex bgColor="blue.600" justifyContent="center" alignItems="center" borderRadius="5px" px="2" mr="2" mb="2" py="0.5">
                                                    <Text mr="2" fontSize="sm">Remove attachment</Text>
                                                    <Text cursor="pointer" _hover={{ backgroundColor: "blue.400" }} onClick={handleFileRemove}><IoMdClose /></Text>
                                                </Flex>
                                                : attachment?.name ?
                                                    <Flex bgColor="blue.600" justifyContent="center" alignItems="center" borderRadius="5px" px="2" mr="2" mb="2" py="0.5">
                                                        <Text mr="2" fontSize="sm">{attachment.name}</Text>
                                                        <Text cursor="pointer" _hover={{ backgroundColor: "blue.400" }} onClick={handleFileRemove}><IoMdClose /></Text>
                                                    </Flex>
                                                    : <label htmlFor="upload-button" className='flex justify-center items-end'>
                                                        <Icon fontSize="xl" children={<FaPaperclip />} mr="2" cursor="pointer" mb="1.5" />
                                                        <Input ref={fileIputRef} id="upload-button" type="file" display="none" accept="image/*" onChange={handleFileSelect} />
                                                    </label>
                                            }
                                        </Flex>
                                    </Flex>
                                    <Textarea placeholder='Reason for time off...' value={reason} onChange={(e) => setReason(e.target.value)} />
                                </FormControl>
                                <Flex justifyContent="end" mt="4"><Button colorscheme="messenger" isLoading={loading} onClick={handleUpdateLeave}>Update</Button></Flex>
                            </>
                            : null}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default LeaveStatus;
