import React, { useEffect, useMemo, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
    Select, Flex, Text, Table, Thead, Tr, Th, Tbody, Td, Box, Badge, Button, Icon, FormLabel, FormControl, Input as ChakraInput, InputGroup, InputLeftElement,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, RadioGroup, Stack, Radio, useDisclosure, useToast, Avatar, Grid,
    NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Tabs, TabList, Tab, TabPanels, TabPanel,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,
} from '@chakra-ui/react';

import { FiCompass, FiMail, FiPhone, FiPlus, FiUser, FiSearch } from 'react-icons/fi';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg'
import { ReactComponent as ExportDocIcon } from '../assets/icons/export-doc.svg'
import { format, addYears, subDays } from 'date-fns';
import { FaCrown, FaDyalog } from 'react-icons/fa';
import { Input } from '../components/Form/Input';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import LeaveRequestPanel from '../components/LeaveRequestPanel'
import * as yup from "yup";

import ReactDatepicker from '../components/Datepicker/ReactDatepicker'
import countries from '../utils/countries.json'
import { usePublicData } from '../contexts/publicDataContext';

const inviteFormSchema = yup.object().shape({
    email: yup
        .string()
        .required("Email is required!")
        .email("Type a valid email!"),
    name: yup.string().required("Full name is required!"),
});

const Users = () => {
    const privateAxios = useAxiosPrivate();
    const toast = useToast()
    const { register, handleSubmit, formState, reset, setValue } = useForm({
        resolver: yupResolver(inviteFormSchema),
    });
    const { errors } = formState;
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState(null)
    const [role, setRole] = useState('1')
    const [users, setUsers] = useState([])
    const [birthday, setBirthday] = useState('')
    const [search, setSearch] = useState({ department: null, status: null })
    const [constractualStart, setConstractualStart] = useState('')
    const [constractualEnd, setConstractualEnd] = useState('')
    const { publicData, refreshData } = usePublicData()
    const [newPassword, setNewPassword] = useState('')
    const leaveTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.leaveTypes ? publicData.leaveTypes : []
    }, [publicData])

    const departments = useMemo(() => {
        return !publicData.departments ? [] : publicData.departments
    }, [publicData])
    const [contractCurrentYear, setContractCurrentYear] = useState({ from: null, to: null, allowance: null })
    const [contractNextYear, setContractNextYear] = useState({ from: null, to: null, allowance: null })
    const [loading, setLoading] = useState(false)
    const [leaveHistory, setLeaveHistory] = useState([])
    const [benefitHistory, setBenefitHistory] = useState([])
    const [benefitSummary, setBenefitSummary] = useState([])
    const [contractHistory, setContractHistory] = useState([])
    const [isCreated, setIsCreated] = useState(true)
    const [contract, setContract] = useState(null)
    const [tabNum, setTabNum] = useState(-1)
    const [joinedAt, setJoinedAt] = useState('')
    const [benefitsHistoryType, setBenefitsHistoryType] = useState(null)

    const [createBenefitData, setCreateBenefitData] = useState({ type: '', amount: '', date: new Date().toISOString().slice(0, 10) })
    const benefitTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.benefitTypes ? publicData.benefitTypes : []
    }, [publicData])

    useEffect(() => {
        if (tabNum === 3) {
            getLeaveHistoryByUser()
        } else if (tabNum === 5) {
            getBenefitHistoryByUser()
            getContractHistoryByUser()
        } else {
            return
        }
    }, [tabNum]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getBenefitHistoryByUser()
    }, [contract, isCreated]) // eslint-disable-line react-hooks/exhaustive-deps

    const getLeaveHistoryByUser = async () => {
        const { data } = await privateAxios.get(`/api/admin/users/${modalData.id}/leaves`)

        setLeaveHistory(data)
    }

    const getBenefitHistoryByUser = async () => {
        const contractId = contract ? contract : null
        const { data } = await privateAxios.get(`/api/admin/users/${modalData?.id}/contract/${contractId}/benefits`)
        setBenefitHistory(data.benefits)
        setBenefitSummary(data.summary)
    }

    const getContractHistoryByUser = async () => {
        const { data } = await privateAxios.get(`/api/admin/users/${modalData.id}/contracts`)
        setContractHistory(data)
    }

    const fetchData = async () => {
        try {
            const { data } = await privateAxios.get('/api/admin/users')
            setUsers(data)
        } catch (err) {
            console.log(err)
        }
    }

    const handleConractNextYearOver = () => {
        setContractNextYear({
            ...contractNextYear,
            from: format(addYears(new Date(`${contractCurrentYear.from}T00:00`), 1), 'yyyy-MM-dd'),
            to: format(subDays(addYears(new Date(`${contractCurrentYear.from}T00:00`), 2), 1), 'yyyy-MM-dd')
        })
    }

    const updateContract = async (user) => {
        const contracts = []

        if (Object.values((({ from, to, allowance }) => ({ from, to, allowance }))(contractCurrentYear)).reduce((a, b) => !!a && !!b, true)) { contracts.push({ ...contractCurrentYear, user }) }
        if (Object.values((({ from, to, allowance }) => ({ from, to, allowance }))(contractNextYear)).reduce((a, b) => !!a && !!b, true)) { contracts.push({ ...contractNextYear, user }) }

        if (!contracts.length) return new Promise((resolve) => resolve({ data: { message: "Invalid range" } }))

        const { data, status } = await privateAxios.post('/api/admin/contracts', contracts)

        if (status !== 200) setContractCurrentYear(contracts[0])
        if (status === 200) setContractNextYear(contracts[1])

        return { data }
    }

    const handleContractEdit = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        setLoading(true)

        try {
            const { data: { message } } = await updateContract(modalData.id)
            await refreshData()
            msg = message
        } catch (error) {
            alertType = 'error'
            if (error.response?.data?.message) msg = error.response?.data?.message
            else msg = "Error occurred"
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        setLoading(false)
        fetchData()
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isOpen) {
            reset()
            setContractCurrentYear({ from: null, to: null, allowance: null })
            setContractNextYear({ from: null, to: null, allowance: null })
            setTabNum(-1)
        }
    }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    const onSubmit = async (values) => {
        if (modalType === 'invite') {
            const picked = (({ name, email, department, address, country }) => ({ name, email, department: !department ? 0 : department, address, country }))(values)
            await inviteNewUser(picked)
        } else if (modalType === 'edit') {
            const picked = (({
                edit_name: name,
                edit_email: email,
                edit_department: department,
                edit_phone: phone,
                edit_address: address,
                edit_country: country,
                edit_total_allowance: total_allowance,
                edit_super_password: super_password,
                edit_job_title: job_title
            }) => ({ id: modalData.id, name, email, contractual_info: `${constractualStart} - ${constractualEnd}`, department, phone, address, country, birthday, total_allowance, joinedDate: joinedAt, title: job_title, super_password }))(values)
            await updateUserProfile(picked)
        }

        await fetchData()
    }

    const inviteNewUser = async (info) => {
        let msg = "Error occurred"
        let alertType = 'success'
        let roleStr = 'employee'

        if (role === '3') roleStr = 'admin'
        else if (role === '2') roleStr = 'departmentmanager'
        else roleStr = 'employee'

        try {
            const { data: { message, user } } = await privateAxios.post('/api/admin/users/invite', { ...info, role: roleStr, contractInfo: contractCurrentYear })
            await updateContract(user.id)
            await refreshData()
            msg = message
        } catch (err) {
            alertType = 'error'
            if (err.response?.status === 403) msg = err.response.data.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        onClose()
        reset()
    }

    const handleReInvite = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.post(`/api/admin/users/${modalData.id}/reinvite`)
            msg = message
        } catch {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const updateUserProfile = async (info) => {
        const { id } = info

        let msg = "Error occurred"
        let alertType = 'success'
        let roleStr = 'employee'

        if (role === '3') roleStr = 'admin'
        else if (role === '2') roleStr = 'departmentmanager'
        else roleStr = 'employee'
        if (roleStr === 'admin') info.department = null

        try {
            if (info.contractual_info === ' - ') info.contractual_info = ''
            const { data: { message } } = await privateAxios.put(`/api/admin/users/${id}`, { ...info, role: roleStr })
            await refreshData()
            msg = message
        } catch (err) {
            alertType = 'error'
            if (err.response.status === 403) msg = err.response.data.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
    }

    const handleInvite = () => {
        setModalType('invite')
        onOpen()
    }

    const handleEdit = (user) => {
        setModalType('edit')
        setModalData(user)
        setValue('name', user.name)
        setValue('email', user.email)
        setValue('edit_name', user.name)
        setValue('edit_email', user.email)
        setValue('edit_department', user.department)
        setValue('edit_phone', user.phone)
        setValue('edit_address', user.address)
        setValue('edit_country', user.country)
        setValue('edit_super_password', user.super_password)
        setValue('edit_total_allowance', user.total_allowance)
        setValue('edit_job_title', user.jobTitle)
        setBirthday(user.birthday)
        setJoinedAt(user.joinedDate)
        setRole(user.role === 'admin' ? '3' : user.role === 'employee' ? '1' : '2')
        setContractCurrentYear(user.contract.currentYear ? user.contract.currentYear : { from: null, to: null, allowance: null })
        setContractNextYear(user.contract.nextYear ? user.contract.nextYear : { from: null, to: null, allowance: null })

        if (user.contractual_info) {
            const [from, to] = user.contractual_info.split(' - ')
            setConstractualStart(from)
            setConstractualEnd(to)
        } else {
            setConstractualStart('')
            setConstractualEnd('')
        }

        onOpen()
    }

    const handleDelete = (user) => {
        setModalType('delete');
        setModalData(user)

        onOpen()
    }

    const deleteUser = async () => {
        const { id } = modalData
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.delete(`/api/admin/users/${id}`)
            msg = message
        } catch {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const departmentText = (user) => {
        const department = departments.find(({ id }) => id === user.department)

        if (!department) return ''

        return <Text className='d-flex justify-start items-center'>{department.name}{department.manager === user.id ? <Icon as={FaCrown} ml="2" color="green" /> : null}</Text>
    }

    const filteredUsers = useMemo(() => {
        const { department, status, name } = search
        let ret = [...users]

        if (department) ret = ret.filter((user) => user.department === department)
        if (status) ret = ret.filter((user) => user.status === status)
        if (name) ret = ret.filter((user) => user.name.toLowerCase().includes(name.toLowerCase()))

        return ret
    }, [search, users])

    const handleDeactivate = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.post(`/api/admin/users/${modalData.id}/deactivate`)
            msg = message
        } catch {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const handleActivate = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.post(`/api/admin/users/${modalData.id}/activate`)
            msg = message
        } catch {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const handleSetPassword = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { data: { message } } = await privateAxios.post(`/api/admin/users/${modalData.id}/reset-password`, { newPassword })
            msg = message
        } catch {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
        onClose()
        reset()
    }

    const handleAddLeave = async (data) => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { id } = modalData
            const { data: { message, leave } } = await privateAxios.post(`/api/admin/leaves`, { ...data, user: id })

            setLeaveHistory([leave, ...leaveHistory])
            msg = message
        } catch {
            alertType = 'error'
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        fetchData()
    }

    const handleExport = async (id) => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            setLoading(true)

            const { data } = await privateAxios.post('/api/benefits/report/export-pdf', { user: id })

            const regexAll = /^(.*[\\\/])?(.+)\.(.+)$/;  // eslint-disable-line no-useless-escape
            const ext = data.filename.match(regexAll)[3]

            const outsideRes = await fetch(data.filename);
            const blob = await outsideRes.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `benefit(${modalData.name}).${ext}`;
            link.click();

            msg = data.message
        } catch (error) {
            alertType = 'error'
        }

        setLoading(false)

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
    }

    const createBenefit = async () => {
        let msg = ''
        let alertType = 'success'
        if (!createBenefitData.type || !createBenefitData.amount) {
            toast({
                title: "Please input values correctly!",
                status: "error",
                duration: 4000,
                isClosable: true,
            })
            return
        }
        try {
            const { data } = await privateAxios.post('/api/admin/benefits', {
                userId: modalData.id,
                type: createBenefitData.type,
                amount: createBenefitData.amount,
                date: createBenefitData.date,
                attachment: '',
                note: 'Claimed by Admin'
            })

            msg = data.message
        } catch (error) {
            alertType = 'error'
            if (error?.response?.data?.message) msg = error?.response?.data?.message
            else msg = "Error occurred"
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType === 'success')
            setIsCreated(!isCreated)
        else
            return

    }

    return (
        <>
            <Flex width="100%" justifyContent="space-between" flexDirection={{ base: 'column', xl: 'row' }} gap="20px" alignItems={'center'}>
                <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="20px" justifyContent={{ lg: 'space-between' }}>
                    <Flex alignItems="center" my={{ base: 0, xl: 5 }} flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <InputGroup>
                            <InputLeftElement children={<FiSearch />} />
                            <ChakraInput placeholder='Search...' onChange={(e) => setSearch({ ...search, name: e.target.value })} />
                        </InputGroup>
                    </Flex>
                    <Flex alignItems="center" my={{ base: 0, xl: 5 }} flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <FormLabel my="0">Departments: </FormLabel>
                        <Select placeholder='All Departments' width="xs" onChange={(e) => setSearch({ ...search, department: parseInt(e.target.value) })}>
                            {departments.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                        </Select>
                    </Flex>
                    <Flex alignItems="center" flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <FormLabel my="0">Status: </FormLabel>
                        <Select placeholder='All' width="xs" onChange={(e) => setSearch({ ...search, status: parseInt(e.target.value) })}>
                            <option value='1'>Active</option>
                            <option value='2'>Pending</option>
                            <option value='3'>Deactivated</option>
                        </Select>
                    </Flex>
                </Flex>
                <Button colorScheme="messenger" onClick={handleInvite}><Icon as={FiPlus} mr="2" />Invite new user</Button>
            </Flex>
            <Box overflowX="auto" display="block">
                <Table colorScheme="whiteAlpha">
                    <Thead>
                        <Tr borderBottom="2px #E2E2E2 solid">
                            <Th></Th>
                            <Th>Name</Th>
                            <Th>Department</Th>
                            <Th>Email</Th>
                            <Th>Status</Th>
                            <Th>Current Year</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredUsers.map((user, index) => (
                            <Tr key={index} borderBottom="2px solid #DBDFE2" className='cursor-pointer hover:bg-slate-100' onClick={() => handleEdit(user)}>
                                <Td p="2"><Avatar src={user.avatar} name={user.name} size="sm" /></Td>
                                <Td><Text fontWeight="bold" color="purple.400">{user.name}</Text></Td>
                                <Td > {departmentText(user)}</Td>
                                <Td >{user.email}</Td>
                                <Td >{(user.status) === 1 ? <Badge className="!px-2" colorScheme='green'>Active</Badge> : user.status === 2 ? <Badge className="!px-2">Pending</Badge> : <Badge className="!px-2" colorScheme='red'>Deactivated</Badge>}</Td>
                                <Td >
                                    <span className='whitespace-nowrap'>{user.contract.currentYear ? `${format(new Date(`${user.contract.currentYear.from}T00:00`), 'dd MMMM yyyy')} -` : ''}</span>
                                    <span className='whitespace-nowrap'>{user.contract.currentYear ? ` ${format(new Date(`${user.contract.currentYear.to}T00:00`), 'dd MMMM yyyy')}` : ''}</span>
                                </Td>
                                <Td p="2">
                                    <Flex gap="2" justifyContent="center" alignItems="center">
                                        <div className="cursor-pointer" onClick={() => handleEdit(user)}><EditIcon /></div>
                                        <div className="cursor-pointer" onClick={(e) => { e.stopPropagation(); handleDelete(user) }}><TrashIcon /></div>
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} size={modalType === "edit" ? "6xl" : "xl"}>
                <ModalOverlay />
                <ModalContent bg="white">
                    <ModalHeader>
                        {modalType === 'invite' ?
                            <Text >New User</Text>
                            : null}
                        {modalType === 'delete' ?
                            <Text>Are you sure to delete this user?</Text>
                            : null}
                    </ModalHeader>
                    <ModalBody>
                        <Flex
                            as="form"
                            flexDir="column"
                            onSubmit={handleSubmit(onSubmit)}

                        >
                            {modalType === 'invite' ?
                                <>
                                    <RadioGroup
                                        onChange={setRole}
                                        value={role}
                                        mb="4"
                                        name="role"
                                    >
                                        <Stack direction='row' gap="2">
                                            <Radio value='1'>Employee</Radio>
                                            <Radio value='2'>Department Manager</Radio>
                                            <Radio value='3'>Admin</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {role !== '3'
                                        ?
                                        <FormControl mb="4">
                                            <FormLabel >Department</FormLabel>
                                            <Select placeholder='Select Department' {...register("department")} >
                                                {departments.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                                            </Select>
                                        </FormControl>
                                        : null
                                    }
                                    <Flex gap="4">
                                        <FormControl mb="4">
                                            <FormLabel >Full Name</FormLabel>
                                            <Input
                                                name="name"
                                                error={errors.name}

                                                {...register("name")}
                                            />
                                        </FormControl>
                                        <FormControl mb="4">
                                            <FormLabel >Email</FormLabel>
                                            <Input
                                                name="email"
                                                error={errors.email}

                                                {...register("email")}
                                            />
                                        </FormControl>
                                    </Flex>
                                    <FormControl mb="4">
                                        <FormLabel mb="2">Country</FormLabel>
                                        <Select placeholder="Select country" {...register('country')}>
                                            {countries.map(({ name, value }, index) => <option key={index} value={value}>{name}</option>)}
                                        </Select>
                                    </FormControl>
                                    <FormControl mb="4">
                                        <FormLabel >Address</FormLabel>
                                        <ChakraInput {...register('address')} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel mb="2">Current Year</FormLabel>
                                        <Flex gap="4">
                                            <FormControl mb="4">
                                                <FormLabel>From</FormLabel>
                                                <ReactDatepicker
                                                    minWidth="200px"
                                                    dateFormat="dd-MMMM-yyyy"
                                                    selectedDate={contractCurrentYear?.from ? new Date(`${contractCurrentYear.from}T00:00`) : null}
                                                    onChange={(day) => {
                                                        setContractCurrentYear({
                                                            ...contractCurrentYear,
                                                            from: format(day, 'yyyy-MM-dd'),
                                                            to: format(subDays(addYears(day, 1), 1), 'yyyy-MM-dd')
                                                        })
                                                    }}
                                                />
                                            </FormControl>
                                            <FormControl mb="4">
                                                <FormLabel>To</FormLabel>
                                                <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" disabled selectedDate={contractCurrentYear?.to ? new Date(`${contractCurrentYear.to}T00:00`) : null} />
                                            </FormControl>
                                            <FormControl mb="4">
                                                <FormLabel>Allowances</FormLabel>
                                                <NumberInput width="100%" min={0} value={contractCurrentYear?.allowance ? contractCurrentYear?.allowance : ''} onChange={(value) => setContractCurrentYear({ ...contractCurrentYear, allowance: parseInt(value) })} >
                                                    <NumberInputField />
                                                    <NumberInputStepper>
                                                        <NumberIncrementStepper />
                                                        <NumberDecrementStepper />
                                                    </NumberInputStepper>
                                                </NumberInput>
                                            </FormControl>
                                        </Flex>
                                    </FormControl>
                                    <Button type='submit' colorScheme='green' my="4" isLoading={formState.isSubmitting}>Invite</Button>
                                </>
                                : null}
                            {modalType === 'edit' ?
                                <>
                                    <Tabs onChange={(tab) => setTabNum(tab)}>
                                        <TabList>
                                            <Tab _focus={{ boxShadow: 'none' }}>Profile</Tab>
                                            <Tab _focus={{ boxShadow: 'none' }}>Allowance</Tab>
                                            <Tab _focus={{ boxShadow: 'none' }}>Password</Tab>
                                            <Tab _focus={{ boxShadow: 'none' }}>Leave History</Tab>
                                            <Tab _focus={{ boxShadow: 'none' }}>Emergency Leave</Tab>
                                            <Tab _focus={{ boxShadow: 'none' }}>Benefits Summary</Tab>
                                        </TabList>

                                        <TabPanels>
                                            <TabPanel>
                                                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap="4">
                                                    {modalData?.status === 3 ? <div></div>
                                                        :
                                                        <RadioGroup
                                                            onChange={setRole}
                                                            value={role}
                                                            mb="2"
                                                            p="2"
                                                            name="role"
                                                            className='d-flex items-end'
                                                        >
                                                            <Stack direction='row' justifyContent="space-between" gap="4">
                                                                <Radio value='1'>Employee</Radio>
                                                                <Radio value='2'>Department Manager</Radio>
                                                                <Radio value='3'>Admin</Radio>
                                                            </Stack>
                                                        </RadioGroup>
                                                    }
                                                    <FormControl>
                                                        <FormLabel mb="2">Start date</FormLabel>
                                                        <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={joinedAt ? new Date(`${joinedAt}T00:00`) : null} onChange={(day) => setJoinedAt(format(day, 'yyyy-MM-dd'))} />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel mb="2">Title</FormLabel>
                                                        <ChakraInput
                                                            {...register("edit_job_title")}
                                                        />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel mb="2">Name</FormLabel>
                                                        <InputGroup>
                                                            <InputLeftElement children={<FiUser />} />
                                                            <ChakraInput
                                                                disabled={modalData?.status === 3}
                                                                label="Name"

                                                                mb="2"
                                                                {...register("edit_name")}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl mb="2">
                                                        <FormLabel mb="2">Birthday</FormLabel>
                                                        {(() => {
                                                            const [bYear, bMonth, bDay] = birthday ? birthday.split('-') : format(new Date(), 'yyyy-MM-dd').split('-')
                                                            const birth = birthday ? new Date(bYear, bMonth - 1, bDay) : new Date()
                                                            return <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" disabled={modalData?.status === 3} selectedDate={new Date(birth)} onChange={(day) => setBirthday(format(day, 'yyyy-MM-dd'))} />
                                                        })()}
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel mb="2">Email</FormLabel>
                                                        <InputGroup>
                                                            <InputLeftElement children={<FiMail />} />
                                                            <ChakraInput disabled={modalData?.status === 3} {...register("edit_email")} />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel mb="2">Current Year</FormLabel>
                                                        <ChakraInput disabled value={modalData.contract.currentYear ? `${format(new Date(modalData.contract.currentYear.from), "dd MMMM yyyy")} - ${format(new Date(modalData.contract.currentYear.to), "dd MMMM yyyy")}` : ''} />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel mb="2">Total Allowance</FormLabel>
                                                        <ChakraInput disabled value={modalData.contract.currentYear ? `${modalData.contract.currentYear.allowance}` : ''} />
                                                    </FormControl>
                                                    <FormControl mb="2">
                                                        <FormLabel mb="2">Department</FormLabel>
                                                        <Select {...register("edit_department")} disabled={(role === '3' || modalData?.status === 3)}>
                                                            {departments.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel mb="2">Phone Number</FormLabel>
                                                        <InputGroup>
                                                            <InputLeftElement children={<FiPhone />} />
                                                            <ChakraInput
                                                                disabled={modalData?.status === 3}
                                                                label="Phone Number"
                                                                {...register("edit_phone")}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel mb="2">Address</FormLabel>
                                                        <InputGroup>
                                                            <InputLeftElement children={<FiCompass />} />
                                                            <ChakraInput
                                                                disabled={modalData?.status === 3}
                                                                label="Address"
                                                                {...register("edit_address")}
                                                            />
                                                        </InputGroup>
                                                    </FormControl>
                                                    <FormControl mb="2">
                                                        <FormLabel mb="2">Country</FormLabel>
                                                        <Select {...register("edit_country")} disabled={modalData?.status === 3} >
                                                            {countries.map(({ name, value }, index) => <option key={index} value={value}>{name}</option>)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Flex width="100%" justifyContent="space-between">
                                                    {(modalType === 'edit' && modalData?.status !== 3) ?
                                                        modalData?.status === 2
                                                            ? <Flex>
                                                                <Button colorscheme="messenger" mb="0" mt="6" onClick={handleReInvite}>Resend Invitation</Button>
                                                                <Button colorScheme='red' mb="0" mt="6" ml="2" onClick={handleDeactivate}>Deactivate</Button>
                                                            </Flex>
                                                            : <Flex>
                                                                <Button colorScheme='red' mb="0" mt="6" ml="2" onClick={handleDeactivate}>Deactivate</Button>
                                                            </Flex>
                                                        : <div></div>}
                                                    {modalData?.status !== 3 ?
                                                        <Button type='submit' colorScheme='green' mb="0" mt="6" isLoading={formState.isSubmitting}>{modalType === 'edit' ? 'Save' : modalType === 'invite' ? 'Invite' : null}</Button>
                                                        : <Button colorscheme="messenger" mb="0" mt="6" onClick={handleActivate}>Activate</Button>
                                                    }
                                                </Flex>
                                            </TabPanel>
                                            <TabPanel>
                                                <Flex justifyContent="end">
                                                    <Text fontSize="xl" fontWeight="bold">{`Days Remaining: ${modalData.contract.daysRemaining} days`}</Text>
                                                </Flex>
                                                <FormLabel fontSize="xl" fontWeight="bold">Current Year</FormLabel>
                                                <Flex mb="8" justifyContent="space-between">
                                                    <Flex justifyContent="center" alignItems="center" gap="4">
                                                        <FormLabel m="0">From</FormLabel>
                                                        <ReactDatepicker
                                                            minWidth="200px"
                                                            dateFormat="dd-MMMM-yyyy"
                                                            selectedDate={contractCurrentYear?.from ? new Date(`${contractCurrentYear.from}T00:00`) : null}
                                                            onChange={(day) => {
                                                                setContractCurrentYear({
                                                                    ...contractCurrentYear,
                                                                    from: format(day, 'yyyy-MM-dd'),
                                                                    to: format(subDays(addYears(day, 1), 1), 'yyyy-MM-dd')
                                                                })
                                                            }}
                                                        />
                                                    </Flex>
                                                    <Flex justifyContent="center" alignItems="center" gap="4">
                                                        <FormLabel m="0">To</FormLabel>
                                                        <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" disabled selectedDate={contractCurrentYear?.to ? new Date(`${contractCurrentYear.to}T00:00`) : null} />
                                                    </Flex>
                                                    <Flex justifyContent="center" alignItems="center" gap="4">
                                                        <FormLabel m="0">Allowance</FormLabel>
                                                        <NumberInput width="100%" min={0} value={contractCurrentYear?.allowance ? contractCurrentYear?.allowance : ''} onChange={(value) => setContractCurrentYear({ ...contractCurrentYear, allowance: value })} >
                                                            <InputGroup>
                                                                <InputLeftElement children={<FaDyalog />} />
                                                                <NumberInputField pl="10" placeholder="Current year allowance" />
                                                            </InputGroup>
                                                            <NumberInputStepper>
                                                                <NumberIncrementStepper />
                                                                <NumberDecrementStepper />
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </Flex>
                                                </Flex>
                                                <FormLabel fontSize="xl" fontWeight="bold">Next Year</FormLabel>
                                                <Flex justifyContent="space-between">
                                                    <Flex justifyContent="center" alignItems="center" gap="4">
                                                        <FormLabel m="0">From</FormLabel>
                                                        <ReactDatepicker
                                                            minWidth="200px"
                                                            dateFormat="dd-MMMM-yyyy"
                                                            onMouseDown={handleConractNextYearOver}
                                                            selectedDate={contractNextYear?.from ? new Date(`${contractNextYear.from}T00:00`) : null}
                                                            onChange={(day) => {
                                                                setContractNextYear({
                                                                    ...contractNextYear,
                                                                    from: format(day, 'yyyy-MM-dd'),
                                                                    to: format(subDays(addYears(day, 1), 1), 'yyyy-MM-dd')
                                                                })
                                                            }}
                                                        />
                                                    </Flex>
                                                    <Flex justifyContent="center" alignItems="center" gap="4">
                                                        <FormLabel m="0">To</FormLabel>
                                                        <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" disabled selectedDate={contractNextYear?.to ? new Date(`${contractNextYear.to}T00:00`) : null} />
                                                    </Flex>
                                                    <Flex justifyContent="center" alignItems="center" gap="4">
                                                        <FormLabel m="0">Allowance</FormLabel>
                                                        <NumberInput width="100%" min={0} value={contractNextYear?.allowance ? contractNextYear?.allowance : ''} onChange={(value) => setContractNextYear({ ...contractNextYear, allowance: value })} >
                                                            <InputGroup>
                                                                <InputLeftElement children={<FaDyalog />} />
                                                                <NumberInputField pl="10" placeholder="Current year allowance" />
                                                            </InputGroup>
                                                            <NumberInputStepper>
                                                                <NumberIncrementStepper />
                                                                <NumberDecrementStepper />
                                                            </NumberInputStepper>
                                                        </NumberInput>
                                                    </Flex>
                                                </Flex>
                                                <Flex justifyContent="end" mt="6"><Button colorscheme="messenger" isLoading={loading} onClick={handleContractEdit}>Save</Button></Flex>
                                            </TabPanel>
                                            <TabPanel>
                                                <Flex gap="4" justifyContent="center" flexDir="column" mt="2">
                                                    <Input placeholder="Type the password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                    <Flex justifyContent="end">
                                                        <Button colorscheme="messenger" onClick={handleSetPassword}>Set Password</Button>
                                                    </Flex>
                                                </Flex>
                                            </TabPanel>
                                            <TabPanel>
                                                <Table mb="4">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Type</Th>
                                                            <Th>From</Th>
                                                            <Th>To</Th>
                                                            <Th>Days</Th>
                                                            <Th>Reason</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {leaveHistory.map(({ type, from, to, reason, duration }, index) => (
                                                            <Tr key={index}>
                                                                <Td>{leaveTypes.find(({ id }) => type === id)?.name}</Td>
                                                                <Td>{format(new Date(`${from.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd-MMMM-yyyy')} {from.split(" ")[1]}</Td>
                                                                <Td>{format(new Date(`${to.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd-MMMM-yyyy')} {to.split(" ")[1]}</Td>
                                                                <Td>{duration}</Td>
                                                                <Td>{reason}</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </TabPanel>
                                            <TabPanel>
                                                <LeaveRequestPanel addLeave={(data) => handleAddLeave(data)} />
                                            </TabPanel>
                                            <TabPanel>
                                                <Text my="4" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                                                    Contract Years
                                                </Text>
                                                <Flex width="100%" flexDirection={{ base: 'column', lg: 'row' }} gap="20px" justifyContent={{ base: 'space-between' }} alignItems={'center'}>
                                                    <Select placeholder='All Contracts' maxW='400px' disabled={(role === '3' || modalData?.status === 3)} onChange={(e) => { setContract(e.target.value) }}>
                                                        {contractHistory.map(({ id, from, to }, index) => <option key={index} value={index}>{`${format(new Date(`${from}T00:00`), 'dd MMMM yyyy')} - ${format(new Date(`${to}T00:00`), 'dd MMMM yyyy')}`}</option>)}
                                                    </Select>
                                                    <Button colorScheme='messenger' mr={{ base: 0, md: '6' }} onClick={() => handleExport(modalData.id)}><Icon as={ExportDocIcon} mr="2" />Export PDF</Button>
                                                </Flex>
                                                <Accordion allowMultiple mt={4}>
                                                    <AccordionItem border='0px'>
                                                        <AccordionButton p='0'>
                                                            <Text my="2" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                                                                Summary
                                                                <AccordionIcon />
                                                            </Text>
                                                        </AccordionButton>
                                                        <AccordionPanel pb={4}>
                                                            <Table colorScheme="whiteAlpha" minWidth="860px">
                                                                <Thead>
                                                                    <Tr borderBottom="2px #E2E2E2 solid">
                                                                        <Th px="4">Type</Th>
                                                                        <Th px="4">available</Th>
                                                                        <Th px="4">used</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {benefitSummary.map(({ type, available, used }, index1) =>
                                                                        <Tr key={index1} borderBottom="2px solid #DBDFE2" cursor="pointer" className='transform hover:bg-gray-100 transition-all ease-in last:border-none'>
                                                                            <Td width="30%" px="4">{type}</Td>
                                                                            <Td width="15%" px="4">{type !== "Other expenses" ? available : 0}</Td>
                                                                            <Td width="15%" px="4">{used}</Td>
                                                                        </Tr>
                                                                    )}
                                                                </Tbody>
                                                            </Table>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                                <Text my="4" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                                                    Add claimed benefits
                                                </Text>
                                                <Flex width="100%" flexDirection={{ base: 'column', lg: 'row' }} gap="20px" justifyContent={{ base: 'space-between' }} alignItems={'center'}>
                                                    <Select placeholder='Select Type' onChange={(e) => setCreateBenefitData({ ...createBenefitData, type: e.target.value })}>
                                                        {benefitTypes.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                                                    </Select>
                                                    <Input type="number" min={0} onChange={(e) => setCreateBenefitData({ ...createBenefitData, amount: e.target.value })} />
                                                    <ReactDatepicker
                                                        minWidth="200px"
                                                        dateFormat="dd-MMMM-yyyy"
                                                        weekendSelectable={false}
                                                        selectedDate={new Date(`${createBenefitData.date}T00:00`)}
                                                        onChange={(date) => setCreateBenefitData({ ...createBenefitData, date: format(date, 'yyyy-MM-dd') })} />
                                                    <Button colorScheme='messenger' mr={{ base: 0, md: '6' }} onClick={() => createBenefit()}>Add</Button>
                                                </Flex>
                                                <Accordion allowMultiple mt={4}>
                                                    <AccordionItem border='0px'>
                                                        <AccordionButton p='0'>
                                                            <Text my="2" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                                                                History
                                                                <AccordionIcon />
                                                            </Text>
                                                        </AccordionButton>
                                                        <AccordionPanel pb={4}>
                                                            <Flex justifyContent="end">
                                                                <Flex alignItems="center" gap="4">
                                                                    <Text fontWeight="bold" whiteSpace="nowrap">Benefit Types: </Text>
                                                                    <Select placeholder='Select Type' onChange={(e) => setBenefitsHistoryType(parseInt(e.target.value))}>
                                                                        {benefitTypes.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                                                                    </Select>
                                                                </Flex>
                                                            </Flex>
                                                            <Table colorScheme="whiteAlpha" minWidth="860px">
                                                                <Thead>
                                                                    <Tr borderBottom="2px #E2E2E2 solid">
                                                                        <Th px="0.5">Type</Th>
                                                                        <Th px="0.5">amount</Th>
                                                                        <Th px="0.5">date</Th>
                                                                        <Th px="0.5">status</Th>
                                                                    </Tr>
                                                                </Thead>
                                                                <Tbody>
                                                                    {benefitHistory.filter(({ typeId }) => benefitsHistoryType ? benefitsHistoryType === typeId : true).map((benefit, index) => (
                                                                        <Tr key={index} borderBottom="2px solid #DBDFE2" cursor="pointer" className='transform hover:bg-gray-100 transition-all ease-in last:border-none' >
                                                                            <Td px="0.5" w="48%">{benefit.type}</Td>
                                                                            <Td px="0.5">{benefit.amount}</Td>
                                                                            <Td px="0.5">{format(new Date(`${benefit.date}T00:00`), 'dd-MMMM-yyyy')}</Td>
                                                                            <Td px="0px">
                                                                                {benefit.approvedStatus === 1 ?
                                                                                    <Badge className="!px-2" colorScheme='green'>Approved</Badge>
                                                                                    : benefit.approvedStatus === 2 ?
                                                                                        <Badge className="!px-2">Pending</Badge>
                                                                                        : <Badge className="!px-2" colorScheme='red'>Rejected</Badge>
                                                                                }
                                                                            </Td>
                                                                        </Tr>
                                                                    ))}
                                                                </Tbody>
                                                            </Table>
                                                        </AccordionPanel>
                                                    </AccordionItem>
                                                </Accordion>
                                            </TabPanel>
                                        </TabPanels>
                                    </Tabs>
                                </>
                                : null}
                            {modalType === 'delete' ?
                                <Flex justifyContent="end" gap="2" mt="2">
                                    <Button isLoading={loading} _focus={{ border: 'none' }} colorscheme="messenger" onClick={() => deleteUser()}>Yes</Button>
                                    <Button disabled={loading} _focus={{ border: 'none' }} colorScheme="red" onClick={() => onClose()}>No</Button>
                                </Flex>
                                : null}
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default Users;
