import React, { useEffect, useState, useMemo } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
    Text, Flex, Spinner, Tabs, TabList, Tab, TabPanels, TabPanel, Thead, Table, Tbody, Tr, Th, Td, Input, Select, Button, Icon, useToast, useDisclosure, Link,
    Modal, ModalContent, ModalOverlay, ModalBody, FormControl, FormLabel, Spacer,
} from '@chakra-ui/react';
import { FaPlus, FaSave } from 'react-icons/fa';
import { usePublicData } from '../contexts/publicDataContext';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg'
import { FiSlack } from 'react-icons/fi';
import { timeSlots } from '../utils/constant.js'
import timezones from '../utils/timezones.json'
import { Select as MultiSelct } from "chakra-react-select";

const { REACT_APP_SLACK_INTEGRATE_URI } = process.env

const Setting = () => {
    const privateAxios = useAxiosPrivate();
    const [slack, setSlack] = useState([])
    const toast = useToast()
    const [leaveTypes, setLeaveTypes] = useState([])
    const [creating, setCreating] = useState(false)
    const [editing, setEditing] = useState(false)
    const [leaveType, setLeaveType] = useState({ name: null, description: null, deductible: true })
    const { publicData } = usePublicData()
    const departments = useMemo(() => {
        return !publicData.departments ? [] : publicData.departments
    }, [publicData])
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState(null)
    const [timezone, setTimezone] = useState(null)
    const [oldSuperPass, setOldSuperPass] = useState(null)
    const [newSuperPass, setNewSuperPass] = useState(null)
    const [isChangedPass, setIsChangedPass] = useState(false)
    const [noteCategories, setNoteCategories] = useState([])
    const [noteCategory, setNoteCategory] = useState({ title: null, description: null })

    const mulitSelctOptions = useMemo(() => departments.map(({ id, name }) => ({ value: id, label: name })), [publicData]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSlackChannelChange = async (value, index) => {
        const clonedSlack = [...slack]
        clonedSlack[index].department = value.map(({ value }) => value).join(',')
        const multipleOptions = clonedSlack[index].department.split(',').map((departmentId) => mulitSelctOptions.find(({ value }) => `${value}` === departmentId))
        clonedSlack[index] = { ...clonedSlack[index], multipleOptions }

        setSlack(clonedSlack)

        let msg = ''
        let alertType = 'success'

        try {
            const { id } = clonedSlack[index]
            const { data } = await privateAxios.put(`/api/admin/thirdParty/slack/${id}`, clonedSlack[index])
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        reset()
    }

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/admin/thirdParty/slack')

        const tSlack = data.map((item) => {
            const multipleOptions = item.department.split(',').map((departmentId) => mulitSelctOptions.find(({ value }) => `${value}` === departmentId))
            return { ...item, multipleOptions }
        })
        setSlack(tSlack)

        const { data: _leaveTypes } = await privateAxios.get('/api/admin/leave/types')
        setLeaveTypes(_leaveTypes)

        const { data: tzone } = await privateAxios.get('/api/admin/timezone')
        setTimezone(tzone.canonicalName)

        const { data: superpassword } = await privateAxios.get('/api/admin/superpassword')
        setOldSuperPass(superpassword.super_password)

        const { data: _noteCategories } = await privateAxios.get('/api/categories')
        setNoteCategories(_noteCategories)
    }

    const reset = () => {
        setLeaveType({ name: null, description: null, deductible: true })
        setNoteCategory({ title: null, description: null })
    }

    const showModal = (type, data) => {
        setModalType(type)
        setModalData(data)
        onOpen()
    }

    const handleModalConfirm = async () => {
        let msg = ''
        let alertType = 'success'

        const { id } = modalData

        setEditing(true)
        if (modalType === 'edit') {
            try {
                const { data } = await privateAxios.put(`/api/admin/leave/types/${id}`, modalData)
                await fetchData()
                msg = data.message
            } catch (error) {
                alertType = 'error'
                msg = error.response?.data?.message
            }
        } else if (modalType === 'delete') {
            try {
                const { data } = await privateAxios.delete(`/api/admin/leave/types/${id}`)
                msg = data.message

                await fetchData()
            } catch (error) {
                alertType = 'error'
                msg = error.response?.data?.message
            }
        } else if (modalType === 'slack-edit') {
            try {
                const { data } = await privateAxios.delete(`/api/admin/thirdParty/slack/${id}`)
                await fetchData()
                msg = data.message
            } catch (error) {
                alertType = 'error'
                msg = error.response?.data?.message
            }
        } else if (modalType === 'category_delete') {
            try {
                const { data } = await privateAxios.delete(`/api/admin/categories/${id}`)
                await fetchData()
                msg = data.message
            } catch (error) {
                alertType = 'error'
                msg = error.response?.data?.message
            }
        } else if (modalType === 'category_edit') {
            const { name, description } = modalData
            try {
                const { data } = await privateAxios.put(`/api/admin/categories/${id}`, { title: name, description })
                await fetchData()
                msg = data.message
            } catch (error) {
                alertType = 'error'
                msg = error.response?.data?.message
            }
        }

        setEditing(false)
        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        reset()
        onClose()
    }

    const handleLeaveTypeCreate = async () => {
        let msg = ''
        let alertType = 'success'

        if (!leaveType.name) return

        setCreating(true)
        try {
            const { data } = await privateAxios.post('/api/admin/leave/types', leaveType)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        setCreating(false)
        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        reset()
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleTimeSlotsChange = async (time, index) => {
        const list = [...slack]
        list[index].time = time
        setSlack(list)

        let msg = ''
        let alertType = 'success'

        try {
            const { id } = list[index]
            const { data } = await privateAxios.put(`/api/admin/thirdParty/slack/${id}`, { ...list[index], time: list[index].time })
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        reset()
    }

    const handleTimezoneChange = async (tzone) => {
        setTimezone(tzone)

        let msg = ''
        let alertType = 'success'

        try {
            const { data } = await privateAxios.post(`/api/admin/timezone`, { canonicalName: tzone })
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        reset()
    }

    const handlePassChange = async (password) => {
        setNewSuperPass(password)
        password !== oldSuperPass ? setIsChangedPass(true) : setIsChangedPass(false)
    }

    const handleUpdateSuperPass = async () => {
        let msg = ''
        let alertType = 'success'
        try {
            const { status } = await privateAxios.put('/api/admin/superpassword', { newSuperPass })
            if (status === 200) {
                await fetchData()
                setIsChangedPass(false)

                msg = "Successfully updated superpassword!"
                toast({
                    title: msg,
                    status: alertType,
                    duration: 4000,
                    isClosable: true,
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handleNoteCategoryCreate = async () => {
        let msg = ''
        let alertType = 'success'

        if (!noteCategory.title) return

        setCreating(true)
        try {
            const { data } = await privateAxios.post('/api/admin/categories', noteCategory)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            msg = error.response?.data?.message
        }

        setCreating(false)
        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        reset()
    }

    return (
        <>
            <Tabs mt="4">
                <TabList>
                    <Tab _focus={{ boxShadow: 'none' }}>Slack Integration</Tab>
                    <Tab _focus={{ boxShadow: 'none' }}>Leave Types</Tab>
                    <Tab _focus={{ boxShadow: 'none' }}>Super Password</Tab>
                    <Tab _focus={{ boxShadow: 'none' }}>Note Categories</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <Flex justifyContent="space-between" mt="6" mb="4">
                            <Flex>
                                <Select value={timezone ?? ''} onChange={(e) => handleTimezoneChange(e.target.value)}>
                                    {timezones.map(({ canonicalName, text }, index) => <option key={index} value={canonicalName}>{text}</option>)}
                                </Select>
                            </Flex>
                            <Button display="flex" gap="2" _hover={{ textDecoration: 'none', color: 'white' }} colorScheme="messenger" as={Link} href={REACT_APP_SLACK_INTEGRATE_URI}>
                                <FiSlack />
                                Add to slack
                            </Button>
                        </Flex>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Department</Th>
                                    <Th>Channel</Th>
                                    <Th>Time</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {slack.map((item, index) => (
                                    <Tr key={index}>
                                        <Td>
                                            <MultiSelct
                                                isMulti
                                                name="colors"
                                                options={mulitSelctOptions}
                                                placeholder="Select some colors..."
                                                closeMenuOnSelect={false}
                                                value={item.multipleOptions}
                                                onChange={(value) => handleSlackChannelChange(value, index)}
                                            />
                                        </Td>
                                        <Td>{item.name}</Td>
                                        <Td>
                                            <Select width="150px" value={item.time} onChange={(e) => handleTimeSlotsChange(e.target.value, index)}>
                                                {timeSlots.map(({ label }, index) => <option key={index} value={label}>{label}</option>)}
                                            </Select>
                                        </Td>
                                        <Td><div className="cursor-pointer" onClick={() => showModal('slack-edit', item)} ><TrashIcon /></div></Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Text fontSize="2xl" fontWeight="bold" my="6">Add or Remove type of Leaves</Text>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Description</Th>
                                    <Th>Deductible</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {leaveTypes.map(({ id, name, description, deductible }, index) => (
                                    <Tr key={index}>
                                        <Td>{name}</Td>
                                        <Td>{description}</Td>
                                        <Td>{deductible ? 'Yes' : 'No'}</Td>
                                        <Td>
                                            <Flex gap="2" justifyContent="center" alignItems="center">
                                                <div className="cursor-pointer" onClick={() => showModal('edit', { id, name, description, deductible })}><EditIcon /></div>
                                                <div className="cursor-pointer" onClick={() => showModal('delete', { id, name, description, deductible })}><TrashIcon /></div>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))}
                                <Tr>
                                    <Td><Input value={leaveType?.name ? leaveType.name : ''} onChange={(e) => setLeaveType({ ...leaveType, name: e.target.value })} /></Td>
                                    <Td><Input value={leaveType?.description ? leaveType.description : ''} onChange={(e) => setLeaveType({ ...leaveType, description: e.target.value })} /></Td>
                                    <Td><Select value={leaveType?.deductible ? "1" : "0"} onChange={(e) => setLeaveType({ ...leaveType, deductible: !!parseInt(e.target.value) })}  ><option value="1">Yes</option><option value="0">No</option></Select></Td>
                                    <Td>
                                        <Flex justifyContent="center">
                                            {creating
                                                ? <Spinner />
                                                : <Button disabled={!leaveType?.name} size="sm" fontSize="sm" colorScheme="messenger" onClick={handleLeaveTypeCreate} ><Icon as={FaPlus} fontSize="16" /></Button>
                                            }
                                        </Flex>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TabPanel>
                    <TabPanel>
                        <Text fontSize="2xl" fontWeight="bold" my="6">Manage Super Password</Text>
                        <Flex>
                            <Input maxW='500px' defaultValue={oldSuperPass} onChange={(e) => { handlePassChange(e.target.value) }} />
                            <Spacer />
                            <Button onClick={handleUpdateSuperPass} disabled={!isChangedPass} colorScheme='messenger'><Icon as={FaSave} fontSize="16" /></Button>
                        </Flex>
                    </TabPanel>
                    <TabPanel>
                        <Text fontSize="2xl" fontWeight="bold" my="6">Add or Remove category of note</Text>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Title</Th>
                                    <Th>Description</Th>
                                    <Th></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {noteCategories.map(({ id, title, description }, index) => (
                                    <Tr key={index}>
                                        <Td>{title}</Td>
                                        <Td>{description}</Td>
                                        <Td>
                                            <Flex gap="2" justifyContent="center" alignItems="center">
                                                <div className="cursor-pointer" onClick={() => showModal('category_edit', { id, name: title, description })}><EditIcon /></div>
                                                <div className="cursor-pointer" onClick={() => showModal('category_delete', { id })}><TrashIcon /></div>
                                            </Flex>
                                        </Td>
                                    </Tr>
                                ))}
                                <Tr>
                                    <Td><Input value={noteCategory?.title ? noteCategory.title : ''} onChange={(e) => setNoteCategory({ ...noteCategory, title: e.target.value })} /></Td>
                                    <Td><Input value={noteCategory?.description ? noteCategory.description : ''} onChange={(e) => setNoteCategory({ ...noteCategory, description: e.target.value })} /></Td>
                                    <Td>
                                        <Flex justifyContent="center">
                                            {creating
                                                ? <Spinner />
                                                : <Button disabled={!noteCategory?.title} size="sm" fontSize="sm" colorScheme="messenger" onClick={handleNoteCategoryCreate} ><Icon as={FaPlus} fontSize="16" /></Button>
                                            }
                                        </Flex>
                                    </Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TabPanel>
                </TabPanels>
            </Tabs >
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent bg="white" >
                    <ModalBody my="2">
                        {modalType === 'delete' || modalType === 'slack-edit' || modalType === 'category_delete'
                            ? <Text mb="2" fontSize="xl" fontWeight="bold">{`Are you sure to delete this ${modalType === 'delete' ? 'leave type' :
                                modalType === 'slack-edit' ? 'it' :
                                    modalType === 'category_delete' ? 'note category' : ''
                                }?`}</Text>
                            : <Flex flexDir="column">
                                <FormControl mb="2">
                                    <FormLabel>{modalType === 'category_edit' ? 'Title' : 'Name'}</FormLabel>
                                    <Input value={modalData?.name} onChange={(e) => setModalData({ ...modalData, name: e.target.value })} />
                                </FormControl>
                                <FormControl mb="2">
                                    <FormLabel>Description</FormLabel>
                                    <Input value={modalData?.description} onChange={(e) => setModalData({ ...modalData, description: e.target.value })} />
                                </FormControl>
                                {modalType === 'category_edit' ? null : <FormControl mb="2">
                                    <FormLabel>Deductible</FormLabel>
                                    <Select value={modalData?.deductible ? "1" : "0"} onChange={(e) => setModalData({ ...modalData, deductible: !!parseInt(e.target.value) })}  >
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </Select>
                                </FormControl>}
                            </Flex>
                        }
                        <Flex justifyContent="end" gap="2" mt="2">
                            <Button isLoading={editing} _focus={{ border: 'none' }} colorscheme="messenger" onClick={handleModalConfirm}>{modalType === 'edit' ? 'Save' : 'Yes'}</Button>
                            <Button disabled={editing} _focus={{ border: 'none' }} colorScheme="red" onClick={() => onClose()}>{modalType === 'edit' ? 'Cancel' : 'No'}</Button>
                        </Flex>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default Setting;
