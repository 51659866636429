import React, { useEffect, useState } from 'react';
import {
    Flex, Text, Grid, Icon, GridItem, Avatar
} from '@chakra-ui/react';
import { format } from 'date-fns';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { ReactComponent as AbsenteeIcon } from '../assets/icons/absentee.svg'

const Home = () => {
    const privateAxios = useAxiosPrivate();
    const [contracturalInfo, setContracturalInfo] = useState('')
    const [birthday, setBirthday] = useState([])
    const [absentees, setAbsentees] = useState([])

    const AbsenteeItem = ({ leave }) => {
        return (
            <Flex border="1px #ECEDEF solid" boxShadow="0px 10px 20px 0px #0000001A" borderRadius="4px" padding="4" gap="2">
                <Flex gap="2" alignItems="center" justifyContent="center" minWidth="100px">
                    <Avatar name={leave.user.name} src={leave.user.avatar} size="md" />
                    <Flex flexDirection="column">
                        <Text fontWeight="bold">{leave.user.name}</Text>
                        <Text fontSize="xs" color="#52595F">
                            {format(new Date(`${leave.from.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd MMMM yyyy')} {leave.from.split(" ")[1]} - 
                            {format(new Date(`${leave.to.split(" ")[0]}T00:00`.replace(/[.]/g, "-")), 'dd MMMM yyyy')} {leave.to.split(" ")[1]}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        )
    }

    const BirthdayItem = ({ user }) => {
        return (
            <Flex border="1px #ECEDEF solid" boxShadow="0px 10px 20px 0px #0000001A" borderRadius="4px" padding="4" gap="2" className="congrats-bg">
                <Flex alignItems="center" justifyContent="center" minWidth="100px" gap="2">
                    <Avatar name={user.name} src={user.avatar} size="md" />
                    <Text fontWeight="bold">{user.name}</Text>
                </Flex>
            </Flex>
        )
    }

    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/report/me')

        setContracturalInfo(data.contracturalInfo)

        const { data: reports } = await privateAxios.get('/api/admin/reports/today')
        setBirthday(reports.birthday)
        setAbsentees(reports.absentees)
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)' }} gap={6} mt="4" mr={2}>
                <Flex flexDirection='column' borderRadius={'6'} border="1px #E2E2E2 solid" p="4" boxShadow="0px 10px 20px 0px #0000001A">
                    <Text fontSize='xl' fontWeight="bold">Your Contractural Year</Text>
                    {contracturalInfo?.from ? <Text fontSize='2xl' ml="4">{`${format(new Date(`${contracturalInfo?.from}T00:00`), 'dd MMMM yyyy')} - ${format(new Date(`${contracturalInfo?.to}T00:00`), 'dd MMMM yyyy')}`}</Text> : null}
                </Flex>
                <Flex flexDirection='column' borderRadius={'6'} border="1px #E2E2E2 solid" p="4" boxShadow="0px 10px 20px 0px #0000001A">
                    <Text fontSize='xl' fontWeight="bold">Total Allowances</Text>
                    <Text fontSize='2xl' ml="4">{contracturalInfo?.allowance ? `${contracturalInfo?.allowance} days` : ''}</Text>
                </Flex>
                <Flex flexDirection='column' borderRadius={'6'} border="1px #E2E2E2 solid" p="4" boxShadow="0px 10px 20px 0px #0000001A">
                    <Text fontSize='xl' fontWeight="bold">Days Remaining</Text>
                    <Text fontSize='2xl' ml="4">{`${contracturalInfo?.daysRemaining ?? 0} days`}</Text>
                </Flex>
            </Grid>
            <Text mt="8" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                <Icon as={AbsenteeIcon} mr="2" />
                Today's absentees
                <p className="nums-icon">{absentees.length}</p>
            </Text>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={6} mt="5" ml={2}>
                {absentees.map((leave, index) => {
                    return (
                        <GridItem w='100%' key={index}>
                            <AbsenteeItem leave={leave} />
                        </GridItem>
                    )
                })}
            </Grid>
            <Text mt="6" fontSize="xl" fontWeight="bold" className='d-flex jutify-center items-center'>
                <Icon as={AbsenteeIcon} mr="2" />
                Today's birthday
                <p className="nums-icon">{birthday.length}</p>
            </Text>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)', xl: 'repeat(4, 1fr)' }} gap={6} mt="5" ml={2}>
                {birthday.map((user, index) => {
                    return (
                        <GridItem w='100%' key={index}>
                            <BirthdayItem user={user} />
                        </GridItem>
                    )
                })}
            </Grid>
        </>
    );
};

export default Home;
