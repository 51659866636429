import React, { useEffect, useMemo, useState } from 'react';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import {
    Select, Flex, Text, Table, Thead, Tr, Th, Tbody, Td, Box, Badge, Button, FormLabel, FormControl, Avatar,
    Icon, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, useToast, Textarea
} from '@chakra-ui/react';
import ReactDatepicker from '../components/Datepicker/ReactDatepicker'
import { ReactComponent as ExportDocIcon } from '../assets/icons/export-doc.svg'
import { FaDownload, FaTrash } from 'react-icons/fa';
import { useAuth } from '../contexts/authContext';
import { usePublicData } from '../contexts/publicDataContext';
import { leaveStatus } from '../utils/constant';
import { format } from 'date-fns';

const Leave = () => {
    const privateAxios = useAxiosPrivate();
    const { user } = useAuth();
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState(null)
    const isAdmin = useMemo(() => user?.role === 'admin', [user])
    const [leaves, setLeaves] = useState([])
    const [reason, setReason] = useState('')
    const [exportOptions, setExportOptions] = useState({ from: null, to: null })
    const { publicData } = usePublicData()
    const departments = useMemo(() => {
        if (!publicData) return []
        return publicData.departments ? publicData.departments : []
    }, [publicData])
    const [searchKey, setSearchKey] = useState({ department: null, status: null })
    const leaveTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.leaveTypes ? publicData.leaveTypes : []
    }, [publicData])
    const [loading, setLoading] = useState(false)


    const fetchData = async () => {
        const route = isAdmin ? '/api/admin/leaves' : '/api/manager/leaves'

        const { data } = await privateAxios.get(route)
        setLeaves(data)
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios, isAdmin]) // eslint-disable-line react-hooks/exhaustive-deps

    // const handleDateSelect = (date) => {
    //     setSelectedDate(date)
    // }

    const openApproveModal = (leave) => {
        setModalType('approve')
        setModalData(leave)
        onOpen()
    }

    const openRejectModal = (leave) => {
        setModalType('reject')
        setModalData(leave)
        onOpen()
    }

    const approve = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {

            let route = `/api/manager/leaves/${modalData.id}/approve`
            if (isAdmin) route = `/api/admin/leaves/${modalData.id}/approve`

            const { data } = await privateAxios.post(route)

            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        onClose()
    }

    const reject = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {

            let route = `/api/manager/leaves/${modalData.id}/reject`
            if (isAdmin) route = `/api/admin/leaves/${modalData.id}/reject`

            const { data } = await privateAxios.post(route, { reason })

            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        onClose()
    }

    const handleYes = () => {
        if (modalType === 'approve') approve()
        else reject()
    }

    const handleDownload = async (file) => {
        const regexAll = /^(.*[\\\/])?(.+)\.(.+)$/; // eslint-disable-line no-useless-escape
        const ext = file.match(regexAll)[3]

        const outsideRes = await fetch(file);
        const blob = await outsideRes.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `report.${ext}`;
        link.click();
    }

    const filteredLeaves = useMemo(() => {
        const { department, status, type } = searchKey
        let ret = [...leaves]

        if (department) ret = ret.filter((leave) => leave.user.department.id === parseInt(department))
        if (status) ret = ret.filter((leave) => leave.approvedStatus === parseInt(status) || leave.acknowledgedStatus === parseInt(status))
        if (type) ret = ret.filter((leave) => leave.type === parseInt(type))

        return ret
    }, [leaves, searchKey])

    const openExportPdfModal = () => {
        setModalType('export-pdf')
        onOpen()
    }

    const handleExport = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            setLoading(true)

            const { from, to } = exportOptions
            const { data } = await privateAxios.post('/api/leaves/report/export-pdf', { from, to })

            const regexAll = /^(.*[\\\/])?(.+)\.(.+)$/;  // eslint-disable-line no-useless-escape
            const ext = data.filename.match(regexAll)[3]

            const outsideRes = await fetch(data.filename);
            const blob = await outsideRes.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `report(${format(new Date(`${from}T00:00`), "dd-MMMM-yyyy")} - ${format(new Date(`${to}T00:00`), "dd-MMMM-yyyy")}).${ext}`;
            link.click();

            msg = data.message
        } catch (error) {
            alertType = 'error'
        }

        setLoading(false)

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })
    }

    useEffect(() => {
        if (!isOpen) {
            setExportOptions({ from: null, to: null })
        }
    }, [isOpen])

    const openDeleteModal = (leave) => {
        setModalType('delete')
        setModalData(leave)
        onOpen()
    }

    const handleDeleteLeave = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { id } = modalData
            const { data } = await privateAxios.delete(`/api/leaves/${id}`)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        onClose()
        setLoading(false)
    }

    return (
        <>
            <Flex width="100%" justifyContent="space-between" flexDirection={{ base: 'column', xl: 'row' }} gap="20px" alignItems={'center'} >
                <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="20px" justifyContent={{ lg: 'space-between' }}>
                    <Flex alignItems="center" my={{ base: 0, xl: 5 }} flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <FormLabel my="0">Departments: </FormLabel>
                        <Select placeholder='All Departments' onChange={(e) => setSearchKey({ ...searchKey, department: e.target.value })}>
                            {departments.map(({ name, id }, index) => <option key={index} value={id}>{name}</option>)}
                        </Select>
                    </Flex>
                    <Flex alignItems="center" flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <FormLabel my="0">Status: </FormLabel>
                        <Select placeholder='All' onChange={(e) => setSearchKey({ ...searchKey, status: e.target.value })}>
                            <option value={leaveStatus.LEAVE_STATUS_APPROVED}>Approved</option>
                            <option value={leaveStatus.LEAVE_STATUS_PENDING}>Pending</option>
                            <option value={leaveStatus.LEAVE_STATUS_REJECTED}>Rejected</option>
                        </Select>
                    </Flex>
                    <Flex alignItems="center" flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <FormLabel my="0">Type: </FormLabel>
                        <Select placeholder='All' onChange={(e) => setSearchKey({ ...searchKey, type: e.target.value })}>
                            {leaveTypes.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
                        </Select>
                    </Flex>
                </Flex>
                {isAdmin ? <Button colorScheme='messenger' mr={{ base: 0, md: '6' }} onClick={openExportPdfModal}><Icon as={ExportDocIcon} mr="2" />Export PDF</Button> : null}
            </Flex>
            <Box overflowX="auto" display="block">
                <Table colorScheme="whiteAlpha" minWidth="860px">
                    <Thead>
                        <Tr borderBottom="2px #E2E2E2 solid">
                            <Th px="0.5"></Th>
                            <Th px="0.5">Name</Th>
                            <Th px="0.5">Type</Th>
                            <Th px="0.5">From</Th>
                            <Th px="0.5">To</Th>
                            <Th px="0.5">Reason</Th>
                            <Th px="0.5">Days</Th>
                            <Th px="0.5"></Th>
                            <Th px="0.5">Manager</Th>
                            {isAdmin ? <Th px="0.5">Approved</Th> : null}
                            <Th px="0.5"></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredLeaves.map((leave, index) => (
                            <Tr key={index} borderBottom="2px solid #DBDFE2" >
                                <Td px="0.5"><Avatar name={leave.user.name} src={leave.user.avatar} size="sm" /></Td>
                                <Td px="0.5"><Text fontWeight="bold">{leave.user.name}</Text></Td>
                                <Td px="0.5">{leaveTypes.find(({ id }) => id === leave.type)?.name}</Td>
                                <Td px="0.5">{format(new Date(`${leave.from.split(" ")[0]}T00:00`.replace(/[.]/g, "-")),'dd-MMMM-yyyy')} {leave.from.split(" ")[1]}</Td>
                                <Td px="0.5">{format(new Date(`${leave.to.split(" ")[0]}T00:00`.replace(/[.]/g, "-")),'dd-MMMM-yyyy')} {leave.to.split(" ")[1]}</Td>
                                <Td px="0.5">{leave.reason}</Td>
                                <Td px="0.5">{leave.duration}</Td>
                                <Td>{leave.attachment ? <FaDownload onClick={() => handleDownload(leave.attachment)} cursor="pointer" /> : null}</Td>
                                <Td px="0.5">{leave.acknowledgedStatus === 1 ? <Badge className="!px-2" colorScheme='green'>Approved</Badge> : leave.acknowledgedStatus === 2 ? <Badge className="!px-2">Pending</Badge> : <Badge className="!px-2" colorScheme='red'>Rejected</Badge>}</Td>
                                {isAdmin ? <Td px="0.5">{leave.approvedStatus === 1 ? <Badge className="!px-2" colorScheme='green'>Approved</Badge> : leave.approvedStatus === 2 ? <Badge className="!px-2">Pending</Badge> : <Badge className="!px-2" colorScheme='red'>Rejected</Badge>}</Td> : null}
                                <Td px="0px" textAlign="center">
                                    {(leave.approvedStatus === 2 && isAdmin) || (leave.acknowledgedStatus === 2 && !isAdmin) ?
                                        <>
                                            <Button
                                                my="1"
                                                size="xs"
                                                fontSize="xs"
                                                colorScheme="green"
                                                onClick={() => openApproveModal(leave)}
                                            >Approve</Button>
                                            <Button
                                                ml="2"
                                                my="1"
                                                size="xs"
                                                fontSize="xs"
                                                colorScheme="red"
                                                onClick={() => openRejectModal(leave)}
                                            >Reject</Button>
                                        </>
                                        : null
                                    }
                                    {(isAdmin && leave.approvedStatus !== 2) ?
                                        <Button colorScheme="purple" size="sm" p="0" onClick={() => openDeleteModal(leave)}><FaTrash /></Button>
                                        : null
                                    }
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent bg="white" >
                    <ModalHeader>
                        {modalType === 'delete' ?
                            <Text fontSize="xl">Are you sure to delete it?</Text>
                            : null}
                        {modalType === 'approve' ?
                            <Text>Are you sure to approve this request?</Text>
                            : null}
                        {modalType === 'reject' ?
                            <Text>Are you sure to reject this request?</Text>
                            : null}
                        {modalType === 'export-pdf' ?
                            <>
                                <Text>Export data as a pdf document</Text>
                            </>
                            : null}
                    </ModalHeader>
                    <ModalBody mb="2">
                        {modalType === 'delete' ?
                            <Flex gap="2" justifyContent="end">
                                <Button colorScheme="purple" onClick={handleDeleteLeave} size="md">Yes</Button>
                                <Button colorScheme="pink" onClick={onClose}>No</Button>
                            </Flex>
                            : null}
                        {modalType === 'reject' ?
                            <FormControl>
                                <FormLabel>Reason</FormLabel>
                                <Textarea placeholder="Write reason here" value={reason} onChange={(e) => setReason(e.target.value)} />
                            </FormControl>
                            : null}
                        {modalType === 'approve' || modalType === 'reject' ?
                            <Flex justifyContent="end" gap="2" mt="4">
                                <Button colorscheme="messenger" onClick={handleYes}>Yes</Button>
                                <Button colorScheme="red" onClick={onClose}>No</Button>
                            </Flex>
                            : null}
                        {modalType === 'export-pdf' ?
                            <>
                                <Flex gap="4">
                                    <FormControl>
                                        <FormLabel>From</FormLabel>
                                        <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={exportOptions.from ? new Date(`${exportOptions.from}T00:00`) : null} onChange={(day) => setExportOptions({ ...exportOptions, from: format(day, 'yyyy-MM-dd') })} />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel>To</FormLabel>
                                        <ReactDatepicker minWidth="200px" dateFormat="dd-MMMM-yyyy" selectedDate={exportOptions.to ? new Date(`${exportOptions.to}T00:00`) : null} onChange={(day) => setExportOptions({ ...exportOptions, to: format(day, 'yyyy-MM-dd') })} />
                                    </FormControl>
                                </Flex>
                                <Flex justifyContent="end" gap="2" mt="6">
                                    <Button isLoading={loading} colorscheme="messenger" onClick={handleExport}>Export</Button>
                                </Flex>
                            </>
                            : null}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default Leave;
