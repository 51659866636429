import React, { useMemo } from 'react';
import {
    Image, IconButton, Avatar, Box, CloseButton, Flex, VStack, Icon, Drawer, DrawerContent, Text,
    useDisclosure,
} from '@chakra-ui/react';
import { FiGift, FiHome, FiUsers, FiFlag, FiCalendar, FiMenu, FiSettings, FiCornerRightUp, FiFolder } from 'react-icons/fi';
import { FaCoins, FaDatabase, FaRegFolderOpen, FaRegStickyNote } from 'react-icons/fa';
import { useAuth } from '../contexts/authContext';
import { usePublicData } from '../contexts/publicDataContext';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg'
import { ReactComponent as HomeIcon } from '../assets/icons/home.svg'
import { ReactComponent as LeaveIcon } from '../assets/icons/leave.svg'
import { ReactComponent as TeamIcon } from '../assets/icons/group.svg'

import MainLogo from '../assets/Castr_main_logo_light-1.svg'

const LinkItems = [
    { name: 'Overview', icon: HomeIcon, to: '/overview', isAdminRoute: true },
    { name: 'Overview', icon: FiHome, to: '/home', isAdminRoute: false, isManagerRoute: true, isEmployeeRoute: true },
    { name: 'Users', icon: FiUsers, to: '/users', isAdminRoute: true },
    { name: 'Departments', icon: FiFlag, to: '/department', isAdminRoute: true },
    { name: 'Notes', icon: FaRegStickyNote, to: '/notes', isAdminRoute: true, isEmployeeRoute: true },
    { name: 'Yearly Insights', icon: FiCalendar, to: '/yearly', isAdminRoute: true },
    { name: 'Leave Requests', icon: LeaveIcon, to: '/leave', isAdminRoute: true, isManagerRoute: false },
    { name: 'Team Requests', icon: TeamIcon, to: '/leave', isAdminRoute: false, isManagerRoute: true },
    { name: 'Leave Request Status', icon: FiCornerRightUp, to: '/leave-status', isManagerRoute: true, isEmployeeRoute: true },
    { name: 'Request Leave', icon: LeaveIcon, to: '/leave-request', isAdminRoute: false, isManagerRoute: true, isEmployeeRoute: true },
    { name: 'Public Holidays', icon: FiGift, to: '/holidays', isAdminRoute: true },
    { name: 'Benefits', icon: FaDatabase, to: '/benefits-overview', isAdminRoute: true },
    { name: 'Benefits Request', icon: FaCoins, to: '/benefits-request', isAdminRoute: true },
    { name: 'Benefits', icon: FiFolder, to: '/benefits', isAdminRoute: false, isManagerRoute: true, isEmployeeRoute: true },
    { name: 'Applied Benefits', icon: FaRegFolderOpen, to: '/benefit-summary', isAdminRoute: false, isManagerRoute: true, isEmployeeRoute: true },
    { name: 'Setting', icon: FiSettings, to: '/setting', isAdminRoute: true },
];

const sideBarDisabledPages = ['/', '/set-password', '/Settings/VerifySlack/Webhook', '/super-password']

export default function SidebarWithHeader({ children }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { user } = useAuth();
    const location = useLocation();

    const showSideBar = useMemo(() => {
        return !sideBarDisabledPages.includes(location.pathname)
    }, [location]);

    const isLoginPage = useMemo(() => {
        return location.pathname === '/' || location.pathname === '/set-password' || location.pathname === '/super-password'
    }, [location]);

    return (
        <Box minH="100vh" bgColor={isLoginPage ? "#DBDFE2" : "white"}>
            {showSideBar
                ?
                <>
                    <SidebarContent
                        onClose={() => onClose}
                        display={{ base: 'none', md: 'block' }}
                    />
                    <Drawer
                        autoFocus={false}
                        isOpen={isOpen}
                        placement="left"
                        onClose={onClose}
                        returnFocusOnClose={false}
                        onOverlayClick={onClose}
                        size="full"
                    >
                        <DrawerContent>
                            <SidebarContent onClose={onClose} />
                        </DrawerContent>
                    </Drawer>
                    <MobileNav onOpen={onOpen} user={user} />
                </>
                : null
            }
            <Box ml={{ base: 0, md: showSideBar ? '256px' : 0 }} px="12" pb="4" pt="84px" minHeight={!showSideBar ? `calc(100vh - 80px)` : '100vh'}>
                {children}
            </Box>
        </Box>
    );
}

const SidebarContent = ({ onClose, ...rest }) => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();
    const { removeData } = usePublicData();

    const filteredSidebarItems = useMemo(() => {
        if (user?.role === 'admin') return LinkItems.filter(({ isAdminRoute }) => isAdminRoute)
        else if (user?.role === 'departmentmanager') return LinkItems.filter(({ isManagerRoute }) => isManagerRoute)
        else return LinkItems.filter(({ isEmployeeRoute }) => isEmployeeRoute)
    }, [user])

    return (
        <Box
            zIndex={1000}
            transition="3s ease"
            bgColor="#FAFAFA"
            w={{ base: 'full', md: 64 }}
            borderRight="1px #ECEDEF solid"
            pos="fixed"
            h="full"
            {...rest}
        >
            <div className="d-flex flex-column justify-between h-100">
                <Flex flexDirection="column" justifyContent="space-between">
                    <Flex h="20" alignItems="center" px="8" mb="2" justifyContent="space-between" borderBottom="1px #ECEDEF solid">
                        <Flex flexDir="column" as="a" href="/">
                            <Image src={MainLogo} className="my-2" width="28" />
                            <Text fontSize="16px" fontWeight="bold" color="#60658D">Leave management app</Text>
                        </Flex>
                        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} fontSize="xl" />
                    </Flex>
                    {filteredSidebarItems.map((link) => (
                        <NavItem key={link.name} icon={link.icon} color="#60658D" to={link.to}>
                            <Text color="#60658D" fontSize="lg">{link.name}</Text>
                        </NavItem>
                    ))}
                </Flex>
                <Flex px="4" py="2" borderTop="1px #ECEDEF solid" justifyContent="space-between" alignItems="center">
                    <Flex>
                        <Avatar
                            size={'md'}
                            className="mr-2"
                            src={user?.avatar}
                        />
                        <VStack
                            display={{ base: 'none', md: 'flex' }}
                            alignItems="flex-start"
                            spacing="1px"
                            ml="2">
                            <Text fontSize="md" cursor="pointer" onClick={() => navigate('/profile')} >{user?.name}</Text>
                            <Text fontSize="xs" color="#60658D">
                                {user?.role === 'admin' ? 'Administrator' : user?.role === 'departmentmanager' ? 'Department Manager' : 'Employee'}
                            </Text>
                        </VStack>
                    </Flex>
                    <LogoutIcon className='cursor-pointer' onClick={() => { logout(); removeData(); }} />
                </Flex>
            </div>
        </Box>
    );
};

const NavItem = ({ icon, to, children, ...rest }) => {
    const location = useLocation();
    return (
        <Link to={to} style={{ textDecoration: 'none' }}>
            <Flex
                align="center"
                p="3"
                ml="2"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                {...rest}
                fontWeight={location.pathname.includes(to) ? '900' : 'normal'}
            >
                {icon && (
                    <Icon
                        mr="2"
                        fontSize="20"
                        as={icon}
                        color="#60658D"
                        fontWeight={location.pathname.includes(to) ? '900' : 'normal'}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};

const MobileNav = ({ onOpen, user, ...rest }) => {
    const location = useLocation();
    const isDepartmentManager = useMemo(() => {
        if (!user) return false
        return user.role === 'departmentmanager'
    }, [user])

    const navHeader = useMemo(() => {
        if (location.pathname.includes('notes') && location.pathname.includes('edit'))
            return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Edit note</Text>

        switch (location.pathname) {
            case '/overview':
                return <Flex flexDirection="column" ps="8" display={{ base: 'none', md: 'block' }}>
                    <Text color="#60658D">Todays Overview</Text>
                    <Text className="d-flex align-items-center" fontSize="26px" fontWeight="bold">{format(new Date(), 'EEEE, MMMM dd, yyyy')}<Icon fontSize="lg" ms="2" as={FiCalendar} /></Text>
                </Flex>
            case '/users':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Users</Text>
            case '/department':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Departments</Text>
            case '/notes':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Notes</Text>
            case '/notes/create':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Create a new note</Text>
            case '/yearly':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Yearly Insights</Text>
            case '/leave':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">{isDepartmentManager ? 'Team Requests' : 'Leave Requests'}</Text>
            case '/holidays':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Public Holidays</Text>
            case '/setting':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Setting</Text>
            case '/profile':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Profile</Text>
            case '/leave-request':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Leave Request Calendar</Text>
            case '/leave-status':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Leave Request Status</Text>
            case '/benefits':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Benefits Overview</Text>
            case '/benefit-summary':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Applied Summary</Text>
            case '/benefits-overview':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Benefits Overview</Text>
            case '/benefits-request':
                return <Text display={{ base: 'none', md: 'block' }} fontSize="26px" ps="8" fontWeight="bold">Benefits Request</Text>
            case '/home':
                return <Flex flexDirection="column" ps="8" display={{ base: 'none', md: 'block' }}>
                    <Text color="#60658D">Todays Overview</Text>
                    <Text className="d-flex align-items-center" fontSize="26px" fontWeight="bold">{format(new Date(), 'EEEE, MMMM dd, yyyy')}<Icon fontSize="lg" ms="2" as={FiCalendar} /></Text>
                </Flex>
            default:
                return <div></div>
        }
    }, [location, isDepartmentManager])

    return (
        <Flex
            ml={{ base: 0, md: '256px' }}
            px={{ base: 4, md: 4 }}
            zIndex={1000}
            pos="fixed"
            height="20"
            width={{ base: "100vw", md: "calc(100vw - 256px)" }}
            alignItems="center"
            justifyContent='space-between'
            bgColor="white"
            borderBottom="1px #ECEDEF solid"
            {...rest}
        >
            {navHeader}
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                fontSize="xl"

                icon={<FiMenu />}
            />

            <Image display={{ base: 'flex', md: 'none' }} src="Castr_main_logo_light-1.svg" width="40" />
        </Flex>
    );
};
