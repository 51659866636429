// import { useRouter } from "next/router";
import { Button, Flex, Stack, Image, Text } from "@chakra-ui/react";
import { Input } from "../components/Form/Input";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { authService } from '../services/authService';
import { useAuth } from '../contexts/authContext';
import { useLocation, useNavigate } from 'react-router-dom'
import { useState } from "react";
import { format } from "date-fns";

const signInFormSchema = yup.object().shape({
    password: yup.string().required("This field is required!"),
    confirmPassword: yup
        .string()
        .required("This field is required!")
        .oneOf([yup.ref("password"), null], "Passwords must match"),
});

export default function SetPassword() {
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState('')
    const [email, setEmail] = useState('')
    const [reset, setReset] = useState(false)
    const { login } = useAuth();

    const parseQuery = (search) => {
        const ret = {}

        if (search) {
            const query = search.slice(1, search.length)
            query.split('&').forEach((item) => {
                const [key, value] = item.split('=')
                ret[key] = value
            })
        }

        return ret
    }

    useEffect(() => {
        const params = parseQuery(location.search)

        if (!params.hasOwnProperty('token') || !params.hasOwnProperty('email')) navigate('/')

        setToken(params.token)
        setEmail(params.email)
        setReset(!!parseInt(params.reset))
    }, [location, navigate])

    const { register, handleSubmit, formState } = useForm({
        resolver: yupResolver(signInFormSchema),
    });

    const { errors } = formState;

    const handleSetPassword = async (values) => {
        try {
            const { status } = await authService.setPassword({ email, token, password: values.password, date: format(new Date(), 'yyyy.MM.dd') });
            if (status === 200) {
                const { status: _status, data } = await authService.login({ email, password: values.password })

                if (_status === 200) {
                    login(data)
                    navigate('/profile', {
                        replace: true
                    });
                }
            }
            else
                console.warn(status);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Flex align="center" justify="center">
            <Flex
                as="form"
                w="100%"
                maxW={400}
                bg="white"
                p="8"
                borderRadius={8}
                flexDir="column"
                onSubmit={handleSubmit(handleSetPassword)}
            >
                <Image src="/Castr_main_logo_light-1.svg" className="mb-10" />
                <Text  fontSize="2xl" className="mb-5 text-center">
                    Set your password
                </Text>
                {!reset ? <Text color="Highlight" className="mb-5">
                    You are invited as a member of Castr Team 👋🏻
                </Text> : null}
                <Stack spacing="4">
                    <Input
                        name="password"
                        type="password"
                        label="Password"
                        error={errors.password}
                        {...register("password")}
                    />
                    <Input
                        name="confirmPassword"
                        type="password"
                        label="Confirm password"
                        error={errors.confirmPassword}
                        {...register("confirmPassword")}
                    />
                </Stack>
                <Button
                    type="submit"
                    mt="6"
                    colorScheme="pink"
                    isLoading={formState.isSubmitting}
                >
                    Set Password
                </Button>
            </Flex>
        </Flex>
    );
}
