import React, { useEffect, useMemo, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
    Select, Flex, Text, Table, Thead, Tr, Th, Tbody, Td, Box, Badge, Button, FormLabel, FormControl, Avatar,
    Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, useDisclosure, useToast, Textarea
} from '@chakra-ui/react';
import {format} from 'date-fns'
import { FaTrash, FaDownload } from 'react-icons/fa';
import { useAuth } from '../../contexts/authContext';
import { usePublicData } from '../../contexts/publicDataContext';
import { Input } from '../../components/Form/Input';

const Requests = () => {
    const privateAxios = useAxiosPrivate();
    const { user } = useAuth();
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [modalType, setModalType] = useState('')
    const [modalData, setModalData] = useState(null)
    const isAdmin = useMemo(() => user?.role === 'admin', [user])
    const [requests, setRequests] = useState([])
    const [reason, setReason] = useState('')
    const { publicData } = usePublicData()

    const benefitTypes = useMemo(() => {
        if (!publicData) return []
        return publicData.benefitTypes ? publicData.benefitTypes : []
    }, [publicData])

    const [searchKey, setSearchKey] = useState({ user: null, type: null })


    const fetchData = async () => {
        const { data } = await privateAxios.get('/api/admin/benefits')
        setRequests(data)
    }

    useEffect(() => {
        fetchData()
    }, [privateAxios, isAdmin]) // eslint-disable-line react-hooks/exhaustive-deps

    const openApproveModal = (leave) => {
        setModalType('approve')
        setModalData(leave)
        onOpen()
    }

    const openRejectModal = (leave) => {
        setModalType('reject')
        setModalData(leave)
        onOpen()
    }

    const approve = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {

            let route = `/api/admin/benefits/${modalData.id}/approve`
            const { data } = await privateAxios.post(route)

            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        onClose()
    }

    const reject = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {

            let route = `/api/manager/benefits/${modalData.id}/reject`
            if (isAdmin) route = `/api/admin/benefits/${modalData.id}/reject`

            const { data } = await privateAxios.post(route, { reason })

            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        if (alertType !== 'success') return

        onClose()
    }

    const handleYes = () => {
        if (modalType === 'approve') approve()
        else reject()
    }

    const filteredRequests = useMemo(() => {
        const { user, type } = searchKey
        let ret = [...requests]

        if (user) ret = ret.filter((request) => request.user.toLowerCase().includes(user.toLowerCase()))
        if (type) ret = ret.filter((request) => request.type === type)

        return ret
    }, [requests, searchKey])

    useEffect(() => {
        if (!isOpen) {
        }
    }, [isOpen])

    const openDeleteModal = (request) => {
        setModalType('delete')
        setModalData(request)
        onOpen()
    }

    const handleDeleteRequest = async () => {
        let msg = "Error occurred"
        let alertType = 'success'

        try {
            const { id } = modalData
            const { data } = await privateAxios.delete(`/api/admin/benefits/${id}`)
            await fetchData()
            msg = data.message
        } catch (error) {
            alertType = 'error'
            console.error(error)
        }

        toast({
            title: msg,
            status: alertType,
            duration: 4000,
            isClosable: true,
        })

        onClose()
    }

    const handleDownload = async (attachments) => {
        const files = JSON.parse(attachments)
        const regexAll = /^(.*[\\\/])?(.+)\.(.+)$/;  // eslint-disable-line no-useless-escape
        for(let file of files){
            const ext = file.match(regexAll)[3]
            const outsideRes = await fetch(file);
            const blob = await outsideRes.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = `report.${ext}`;
            link.click();
        }
    }

    return (
        <>
            <Flex width="100%" justifyContent="space-between" flexDirection={{ base: 'column', xl: 'row' }} gap="20px" alignItems={'center'} >
                <Flex flexDirection={{ base: 'column', lg: 'row' }} gap="20px" justifyContent={{ lg: 'space-between' }}>
                    <Flex alignItems="center" my={{ base: 0, xl: 5 }} flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <FormLabel my="0">User: </FormLabel>
                        <Input name="user" placeholder="Search..." onChange={(e) => setSearchKey({ ...searchKey, user: e.target.value })} />
                    </Flex>
                    <Flex alignItems="center" flexDirection={{ base: 'column', xl: 'row' }} gap="10px">
                        <FormLabel my="0">Type: </FormLabel>
                        <Select placeholder='All' onChange={(e) => setSearchKey({ ...searchKey, type: e.target.value })}>
                            {benefitTypes.map(({ name, id }, index) => <option key={index} value={name}>{name}</option>)}
                        </Select>
                    </Flex>
                </Flex>
            </Flex>
            <Box overflowX="auto" display="block">
                <Table colorScheme="whiteAlpha" minWidth="860px">
                    <Thead>
                        <Tr borderBottom="2px #E2E2E2 solid">
                            <Th px="0.5"></Th>
                            <Th px="0.5">Name</Th>
                            <Th px="0.5">Type</Th>
                            <Th px="0.5">date</Th>
                            <Th px="0.5">amount</Th>
                            <Th px="0.5">documents</Th>
                            <Th px="0.5">reason</Th>
                            {isAdmin ? <Th px="0.5">Approved</Th> : null}
                            <Th px="0.5"></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredRequests.map((request, index) => (
                            <Tr key={index} borderBottom="2px solid #DBDFE2" cursor="pointer" className='transform hover:bg-gray-100 transition-all ease-in' >
                                <Td px="0.5"><Avatar src={request.avatar} name={request.user} size="sm" /></Td>
                                <Td px="0.5"><Text fontWeight="bold">{request.user}</Text></Td>
                                <Td px="0.5">{request.type}</Td>
                                <Td px="0.5">{format(new Date(`${request.date}T00:00`), 'dd-MMMM-yyyy')}</Td>
                                <Td px="0.5">{request.amount}</Td>

                                <Td>{ request.attachment ? <FaDownload onClick={() => handleDownload(request.attachment)} cursor="pointer" /> : null}</Td>
                                <Td>{request.note}</Td>
                                {isAdmin ? <Td px="0.5">{request.approvedStatus === 1 ? <Badge className="!px-2" colorScheme='green'>Approved</Badge> : request.approvedStatus === 2 ? <Badge className="!px-2">Pending</Badge> : <Badge className="!px-2" colorScheme='red'>Rejected</Badge>}</Td> : null}
                                <Td px="0px" textAlign="center">
                                    {(request.approvedStatus === 2 && isAdmin) ?
                                        <>
                                            <Button
                                                my="1"
                                                size="xs"
                                                fontSize="xs"
                                                colorScheme="green"
                                                className="transform hover:scale-110 transition-all ease-in"
                                                onClick={() => openApproveModal(request)}
                                            >Approve</Button>
                                            <Button
                                                ml="2"
                                                my="1"
                                                size="xs"
                                                fontSize="xs"
                                                colorScheme="red"
                                                className="transform hover:scale-110 transition-all ease-in"
                                                onClick={() => openRejectModal(request)}
                                            >Reject</Button>
                                        </>
                                        : null
                                    }
                                    {(isAdmin && request.approvedStatus !== 2) ?
                                        <Button
                                            p="0"
                                            size="sm"
                                            colorScheme="purple"
                                            className="transform hover:scale-110 transition-all ease-in"
                                            onClick={() => openDeleteModal(request)}>
                                            <FaTrash />
                                        </Button>
                                        : null
                                    }
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent bg="white" >
                    <ModalHeader>
                        {modalType === 'delete' ?
                            <Text fontSize="xl">Are you sure to delete it?</Text>
                            : null}
                        {modalType === 'approve' ?
                            <Text>Are you sure to approve this request?</Text>
                            : null}
                        {modalType === 'reject' ?
                            <Text>Are you sure to reject this request?</Text>
                            : null}
                        {modalType === 'export-pdf' ?
                            <>
                                <Text>Export data as a pdf document</Text>
                            </>
                            : null}
                    </ModalHeader>
                    <ModalBody mb="2">
                        {modalType === 'delete' ?
                            <Flex gap="2" justifyContent="end">
                                <Button colorScheme="purple" onClick={handleDeleteRequest} size="md">Yes</Button>
                                <Button colorScheme="pink" onClick={onClose}>No</Button>
                            </Flex>
                            : null}
                        {modalType === 'reject' ?
                            <FormControl>
                                <FormLabel>Reason</FormLabel>
                                <Textarea placeholder="Write reason here" value={reason} onChange={(e) => setReason(e.target.value)} />
                            </FormControl>
                            : null}
                        {modalType === 'approve' || modalType === 'reject' ?
                            <Flex justifyContent="end" gap="2" mt="4">
                                <Button colorscheme="messenger" onClick={handleYes}>Yes</Button>
                                <Button colorScheme="red" onClick={onClose}>No</Button>
                            </Flex>
                            : null}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
};

export default Requests;
