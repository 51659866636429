import { Routes, Route } from 'react-router-dom';

import { AuthProvider } from './contexts/authContext';
import { PublicDataProvider } from './contexts/publicDataContext';

import { StorageProvider } from './contexts/storageContext';
import { persistor } from './helpers/persistor';

import Login from './components/Login';
import AdminLogin from './components/AdminLogin';
import SetPassword from './components/SetPassword';
import RequireAuth from './components/RequireAuth';
import SidebarWithHeader from './components/SideBarWithHeader';
import Overview from './pages/Overview';
import Department from './pages/Department';
import Users from './pages/Users';
import Notes from './pages/Notes';
import NotesCreate from './pages/NotesCreate';
import NotesEdit from './pages/NotesEdit';
import Setting from './pages/Setting';
import Leave from './pages/Leave';
import LeaveStatus from './pages/LeaveStatus';
import LeaveRequest from './pages/LeaveRequest';
import Home from './pages/Home';
import Yearly from './pages/Yearly';
import Profile from './pages/Profile';
import Holidays from './pages/Holidays';
import SlackWebHookRedirect from './pages/SlackWebHookRedirect';
import BenefitOverview from './pages/Benefits/Overview'
import BenefitSummary from './pages/Benefits/Summary'
import AdminOverview from './pages/Benefits/AdminOverview'
import AdminRequests from './pages/Benefits/AdminRequests';

const App = () => {
    return (
        <div className='h-100 bg-neutral-900'>
            <StorageProvider storage={persistor}>
                <AuthProvider>
                    <PublicDataProvider>
                        <SidebarWithHeader>
                            <Routes>
                                <Route path='/' element={<Login />} /> 
                                <Route path='/super-password' element={<AdminLogin />} />
                                <Route path='/set-password' element={<SetPassword />} />
                                <Route path='/Settings/VerifySlack/Webhook' element={<SlackWebHookRedirect />} />
                                <Route element={<RequireAuth />}>
                                    <Route path='/overview' element={<Overview />} />
                                    <Route path='/home' element={<Home />} />
                                    <Route path='/users' element={<Users />} />
                                    <Route path='/department' element={<Department />} />
                                    <Route path='/notes' element={<Notes />} />
                                    <Route path='/notes/create' element={<NotesCreate />} />
                                    <Route path='/notes/:id/edit' element={<NotesEdit />} />
                                    <Route path='/setting' element={<Setting />} />
                                    <Route path='/leave' element={<Leave />} />
                                    <Route path='/leave-status' element={<LeaveStatus />} />
                                    <Route path='/leave-request' element={<LeaveRequest />} />
                                    <Route path='/yearly' element={<Yearly />} />
                                    <Route path='/holidays' element={<Holidays />} />
                                    <Route path='/profile' element={<Profile />} />
                                    <Route path='/benefits' element={<BenefitOverview />} />
                                    <Route path='/benefit-summary' element={<BenefitSummary />} />
                                    <Route path='/benefits-overview' element={<AdminOverview/>} />
                                    <Route path='/benefits-request' element={<AdminRequests />} />
                                </Route>
                            </Routes>
                        </SidebarWithHeader>
                    </PublicDataProvider>
                </AuthProvider>
            </StorageProvider>
        </div>
    );
};

export default App;
