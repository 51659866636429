const USER_KEY = 'user';
const PUBLIC_DATA_KEY = 'public';

export const persistor = {
    // For users
    setUser: (user) => {
        localStorage.setItem(USER_KEY, JSON.stringify(user));
    },

    user: () => {
        return localStorage.key(USER_KEY)
            ? JSON.parse(localStorage.getItem(USER_KEY))
            : null;
    },

    accessToken: () => {
        return this.user()?.accessToken;
    },

    refreshToken: () => {
        return this.user()?.refreshToken;
    },

    removeUser: () => {
        localStorage.removeItem(USER_KEY);
    },

    // For public data
    setPublicData: (publicData) => {
        localStorage.setItem(PUBLIC_DATA_KEY, JSON.stringify(publicData));
    },

    publicData: () => {
        return localStorage.key(PUBLIC_DATA_KEY)
            ? JSON.parse(localStorage.getItem(PUBLIC_DATA_KEY))
            : null;
    },

    removePublicData: () => {
        localStorage.removeItem(PUBLIC_DATA_KEY);
    },
};
